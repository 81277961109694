import React, { useMemo, useEffect, useCallback } from 'react'
import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listJobs, updateJob } from '../actions'
import useListAction from 'core/hooks/useListAction'
import { routes } from 'core/utils/routes'
import { IJobDetailsPageTabs } from '../model'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { jobSelector } from '../selectors'
import JobOverview from './JobOverview'
import EntityPodsPage from '../../common/entity/entity-pods-page'
import EntityEventsPage from '../../common/entity/entity-events-page'
import EntityLogsPage from '../../common/entity/entity-logs-page'
import EntityYamlPage from '../../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import jsYaml from 'js-yaml'
import useUpdateAction from 'core/hooks/useUpdateAction'
import InfoHeader from '../../common/entity/info-header'
import { IJobSelector } from 'k8s/components/cronjobs/job-model'

const { qbert } = ApiClient.getInstance()

export const getJobControllerInfo = (job) => {
  const owners = job?.metadata?.ownerReferences || []
  const controller = owners.find((owner) => {
    return owner.controller
  })
  return controller
}

const defaultJob = {
  volumes: [],
  tolerations: [],
} as IJobSelector

const JobOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const { loading, reload } = useListAction(listJobs, {
    params: { clusterId },
  })
  const jobs = useSelectorWithParams(jobSelector, { clusterId, useGlobalParams: false })
  const job = useMemo(() => jobs.find((job) => job.id === id) || defaultJob, [id, jobs])

  const { update, updating } = useUpdateAction(updateJob)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: job?.clusterName || clusterId,
        id: job?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [job?.clusterName, job?.name, id, clusterId])

  const getJobYaml = useCallback(async () => {
    if (!job) return undefined
    return qbert.getJob(job.clusterId, job.namespace, job.name)
  }, [job])

  const handleJobUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({ clusterId, namespace, name, id: job?.id, body })
    },
    [clusterId, job?.id],
  )

  const headerColumns = useMemo(() => {
    if (!job) return []
    const controller = getJobControllerInfo(job)
    const name = `${controller?.kind || ''}${
      controller?.kind && controller?.name ? '/' : ''
    }${controller?.name || ''}`
    return [
      { label: 'Name', value: job?.name },
      { label: 'Controlled by', value: name },
      { label: 'Completions', value: job?.spec?.completions },
      { label: 'Successful', value: job?.status?.succeeded },
    ]
  }, [job])

  return (
    <>
      <DocumentMeta title="Job Overview" breadcrumbs />
      <InfoHeader columns={headerColumns} />
      <Tabs route={routes.jobs.details}>
        <Tab label="Overview" value={IJobDetailsPageTabs.Overview}>
          <JobOverview job={job} loading={loading} />
        </Tab>
        <Tab label="Pods" value={IJobDetailsPageTabs.Pods}>
          <EntityPodsPage pods={job?.pods} loading={loading} reload={reload} />
        </Tab>
        <Tab label="Events" value={IJobDetailsPageTabs.Events}>
          <EntityEventsPage entity={job} loading={loading} />
        </Tab>
        <Tab label="Logs" value={IJobDetailsPageTabs.Logs}>
          <EntityLogsPage pods={job?.pods} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IJobDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Job"
            entityName={job?.name}
            getYamlFn={getJobYaml}
            handleUpdate={handleJobUpdate}
            loading={updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default JobOverviewPage
