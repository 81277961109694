import { makeStyles } from '@material-ui/styles'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Theme from 'core/themes/model'
import { masterNodesLengthErrorMsg } from 'core/utils/fieldValidators'
import { allPass, sort } from 'ramda'
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import CloudProviderCard from '../common/CloudProviderCard'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import ClusterHostChooser from '../../../infrastructure/components/clusters/bareos/ClusterHostChooser'
import {
  columns,
  initialContext,
} from '../../../infrastructure/components/clusters/bareos/create-templates/PhysicalOneClick'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { clusterActions } from '../../../infrastructure/components/clusters/actions'
import { ClusterCreateTypes } from '../../../infrastructure/components/clusters/model'
import { bareOSClusterTracking } from '../../../infrastructure/components/clusters/tracking'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import DownloadOvaWalkthrough from './download-ova-walkthrough'
import DownloadCliWalkthrough from './download-cli-walkthrough'
import { compareVersions } from 'k8s/util/helpers'
import { sessionActions } from 'core/session/sessionReducers'
import { useDispatch, useSelector } from 'react-redux'
import { routes } from 'core/utils/routes'
import { onboardClusterTracking } from './tracking.js'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { isNilOrEmpty } from 'utils/fp'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import { isUnassignedNode, isConnected } from 'app/plugins/infrastructure/components/nodes/helpers'
import { listSupportedRoleVersions } from '../../../infrastructure/components/clusters/newActions'
import useListAction from 'core/hooks/useListAction'
import { supportedRoleVersionsSelector } from '../../../infrastructure/components/clusters/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  connectionChoices: {
    maxWidth: '800px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 242px)',
    gridGap: theme.spacing(2),
  },
  validatedFormContainer: {
    overflow: 'auto',
  },
  button: {
    gridColumn: '2',
    marginTop: theme.spacing(3),
    width: 'max-content',
  },
  linkText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  spaceAbove: {
    marginTop: theme.spacing(2),
  },
  downloadIcon: {
    marginLeft: theme.spacing(1),
  },
  downloadButton: {
    marginTop: theme.spacing(1),
  },
  reviewCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    padding: theme.spacing(1),
  },
}))

type Option = 'ova' | 'cli'

const segmentTrackingFields = {
  platform: CloudProviders.VirtualMachine, // what should I put for this?
  target: ClusterCreateTypes.OneClick,
}

interface Props {
  onNext: (cb: () => void) => void
  wizardContext: Record<string, any>
  setWizardContext: (values: Record<string, any>) => void
  setSubmitting: (value: boolean) => void
  setClusterId: (clusterId: string) => void
}

export default function CreateBareosClusterPage({
  onNext,
  wizardContext,
  setWizardContext,
  setSubmitting,
  setClusterId,
}: Props) {
  const classes = useStyles()
  const [option, setOption] = useState<Option>('ova')
  const [errorMessage, setErrorMessage] = useState(null)
  const dispatch = useDispatch()
  const onComplete = (success, cluster) => {
    if (!success) return
    const clusterNodeUrl = routes.cluster.legacy.detail.path({
      tab: 'node-health',
      id: cluster?.uuid,
    })
    dispatch(sessionActions.updateSession({ onboardingRedirectToUrl: clusterNodeUrl }))
    setClusterId(cluster?.uuid)
  }
  const [createCluster] = useDataUpdater(clusterActions.create, onComplete)
  const validatorRef = useRef(null)

  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const { loading } = useListAction(listSupportedRoleVersions)
  const kubernetesVersions = useSelector(supportedRoleVersionsSelector)

  const defaultKubernetesVersion = useMemo(() => {
    const versionsList = kubernetesVersions?.map((obj) => obj.roleVersion) || []
    const sortedVersions = sort(compareVersions, versionsList) // this sorts the versions from low-high
    return sortedVersions[sortedVersions.length - 1]
  }, [kubernetesVersions])

  useEffect(() => {
    bareOSClusterTracking.createStarted(segmentTrackingFields)()
    bareOSClusterTracking.oneClick(segmentTrackingFields)()
  }, [])

  const validateMasterNodes = (masterNodes) => {
    if (isNilOrEmpty(masterNodes)) {
      setErrorMessage('A master node must be selected')
      return false
    }
    if (![1, 3, 5].includes(masterNodes.length)) {
      setErrorMessage(masterNodesLengthErrorMsg)
      return false
    }
    setErrorMessage(null)
    return true
  }

  const handleSubmit = useCallback(async () => {
    const isValid =
      validateMasterNodes(wizardContext.masterNodes) && (await validatorRef.current.validate())
    if (!isValid) {
      return false
    }
    onboardClusterTracking.wZCreateClusterOnInfrastructure(option)
    setSubmitting(true)
    const data = {
      ...initialContext,
      segmentTrackingFields,
      clusterType: 'local',
      kubeRoleVersion: defaultKubernetesVersion,
      name: wizardContext.nodes.length === 1 ? wizardContext.clusterName : 'PF9-multi-node-cluster',
      masterNodes: wizardContext.masterNodes,
      workerNodes: wizardContext.workerNodes,
    }
    await createCluster(data)
    setSubmitting(false)
    return true
  }, [validatorRef.current, setSubmitting, defaultKubernetesVersion, wizardContext, option])

  useEffect(() => {
    onNext(handleSubmit)
  }, [handleSubmit])

  return (
    <div>
      <div className={classes.connectionChoices}>
        <CloudProviderCard
          type={CloudProviders.VirtualMachine}
          label="VM Template/OVA"
          active={option === 'ova'}
          onClick={(type) => setOption('ova')}
        />
        <CloudProviderCard
          type={CloudProviders.PhysicalMachine}
          label="Existing Virtual or Physical Infrastructure"
          active={option === 'cli'}
          onClick={(type) => setOption('cli')}
        />
      </div>
      <FormFieldSection title="Attach a master node" step={1}>
        {option === 'ova' ? <DownloadOvaWalkthrough /> : <DownloadCliWalkthrough />}
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          elevated={false}
          triggerSubmit={setupValidator}
        >
          <ClusterHostChooser
            id="nodes"
            selection="multiple"
            title="Waiting for your Ubuntu/CentOs Node to Attach"
            filterFn={allPass([isConnected, isUnassignedNode])}
            value={wizardContext.nodes}
            onChange={(value) => setWizardContext({ nodes: value })}
            allowMasterAndWorkerNodesSelection
            isSingleNodeCluster
            pollForNodes
            required
          />
        </ValidatedForm>
        {errorMessage && (
          <ErrorMessage className={classes.errorMessage}>{errorMessage}</ErrorMessage>
        )}
      </FormFieldSection>
      <FormFieldSection
        className={classes.reviewCard}
        title="Default Settings for New Cluster"
        step={2}
      >
        <FormReviewTable data={initialContext} columns={columns} />
      </FormFieldSection>
    </div>
  )
}
