import React from 'react'
import SelectableCard from 'core/components/SelectableCard'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import Radio from 'core/elements/input/Radio'

interface Props {
  label: string
  subtitle: string
  onClick?: (val) => void
  className?: string
  active: boolean
}

export default function RadioSelectableCard({
  active,
  label,
  subtitle,
  onClick,
  className = undefined,
  ...rest
}: Props) {
  const classes = useStyles({ active })
  return (
    <SelectableCard
      key={`${label}-${active}`}
      id={`${label}-${active}`}
      active={active}
      onClick={onClick}
      className={clsx(classes.card, className)}
      {...rest}
    >
      <div>
        <Radio textWeight="heavy" label={label} checked={active} />
        <Text className={classes.radioSubtitle} variant="body2">
          {subtitle}
        </Text>
      </div>
    </SelectableCard>
  )
}

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.components.selectableCard.activeBorder}`,
    },
    border: ({ active }) =>
      active
        ? `1px solid ${theme.components.selectableCard.activeBorder}`
        : `1px solid ${theme.components.selectableCard.border}`,
    background: ({ active }) =>
      active
        ? hexToRgbaCss(theme.components.selectableCard.activeBackground, 0.1)
        : theme.components.card.background,
    borderRadius: theme.spacing(0.5),
    minWidth: 300,
    '& .card-body': {
      padding: theme.spacing(2),
    },
  },
  radioSubtitle: {
    marginLeft: theme.spacing(4),
    fontSize: 13,
  },
}))
