import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { helm } = ApiClient.getInstance()

const deploymentDetailsActions = ActionsSet.make<DataKeys.DeployedAppDetails>({
  uniqueIdentifier: ['clusterId', 'namespace', 'name'],
  indexBy: ['clusterId', 'namespace', 'name'],
  entityName: entityNamesByKey.DeployedAppDetails,
  cacheKey: DataKeys.DeployedAppDetails,
})

export const loadDeployedAppDetails = deploymentDetailsActions.add(
  new ListAction<
    DataKeys.DeployedAppDetails,
    { clusterId: string; namespace: string; name: string }
  >(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get app deployment details', {
      clusterId,
      namespace,
      name,
    })
    return helm.getReleaseInfo(clusterId, namespace, name)
  }),
)

export const deployedAppActions = ActionsSet.make<DataKeys.DeployedApps>({
  uniqueIdentifier: ['name', 'clusterId', 'namespace', 'chart'],
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.DeployedApps],
  cacheKey: DataKeys.DeployedApps,
})

export const listDeployedApps = deployedAppActions.add(
  new ListAction<DataKeys.DeployedApps, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to list deployed apps', { clusterId })
    return helm.getReleases(clusterId)
  }).addDependency(DataKeys.Apps),
)

export const deployApp = deployedAppActions.add(
  new CreateAction<
    DataKeys.DeployedApps,
    {
      clusterId: string
      namespace: string
      deploymentName: string
      repository: string
      chartName: string
      version: string
      dry?: boolean
      values: unknown
    }
  >(
    async ({
      clusterId,
      namespace,
      deploymentName,
      repository,
      chartName,
      version,
      dry = false,
      values = undefined,
    }) => {
      const body = {
        Name: deploymentName,
        Chart: `${repository}/${chartName}`,
        Dry: dry,
        Version: version,
        Vals: values,
      }
      Bugsnag.leaveBreadcrumb('Attempting to deploy application', { clusterId, namespace, ...body })
      const deployedApp = await helm.deployChart(clusterId, namespace, body)
      trackEvent('Deploy Application', {
        cluster: clusterId,
        appName: deploymentName,
      })
      return deployedApp
    },
  ),
)

export const updateDeployedApp = deployedAppActions.add(
  new UpdateAction<
    DataKeys.DeployedApps,
    {
      clusterId: string
      namespace: string
      deploymentName: string
      repository: string
      chart: string
      action: string
      version?: string
      values?: unknown
    }
  >(
    async ({
      clusterId,
      namespace,
      deploymentName,
      repository,
      chart,
      action,
      version = undefined,
      values = undefined,
    }) => {
      const body = {
        Name: deploymentName,
        Chart: `${repository}/${chart}`,
        Action: action,
        Version: version,
        Vals: values,
      }
      Bugsnag.leaveBreadcrumb('Attempting to update deployed application', {
        clusterId: clusterId,
        ...body,
      })
      await helm.updateRelease(clusterId, namespace, chart, body)
      loadDeployedAppDetails.call({ clusterId, namespace, name: deploymentName })

      trackEvent('Update Application ', {
        cluster: clusterId,
        namespace,
        repository,
        chart,
        appName: deploymentName,
      })
    },
  ),
)

export const deleteDeployedApp = deployedAppActions.add(
  new DeleteAction<DataKeys.DeployedApps, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      const data = {
        Name: name,
      }
      Bugsnag.leaveBreadcrumb('Attempting to delete deployed application', {
        clusterId,
        namespace,
        name,
      })
      await helm.deleteRelease(clusterId, namespace, data)
      trackEvent('Delete Application', {
        cluster: clusterId,
        appName: name,
      })
    },
  ),
)
