import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useCallback, useMemo } from 'react'
import {
  configMapColumns,
  taintsAndTolerationsListTableColumns,
} from '../../common/entity/constants'
import useDataLoader from 'core/hooks/useDataLoader'
import { configMapActions } from '../../config-maps/actions'
import { getConfigMapsForResource } from '../../config-maps/helpers'
import InfoCard from '../../common/entity/info-card'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Progress from 'core/components/progress/Progress'
import { isNilOrEmpty } from 'utils/fp'
import Text from 'core/elements/Text'
import { durationBetweenDates } from 'utils/misc'
import { renderAgeFromTimestamp } from '../../common/entity/helpers'
import { getJobControllerInfo } from './JobOverviewPage'
import LabelsAndAnnotationsTabs from '../../common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Containers from '../../common/entity/containers'
import { renderResourceLabels } from '../../common/entity/labels-and-annotations/helpers'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { getVolumeTypeAndDescription } from 'k8s/components/storage/helpers'
import { ICronjobDetailsPageTabs } from '../model'
import Grid from 'core/elements/grid'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  noneText: {
    margin: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  link: {},
}))

export const renderLastScheduleTime = (time, entity) => {
  if (!time || isNilOrEmpty(entity)) return ''
  return <Text variant="caption1">{`${durationBetweenDates({ labels: ['d'] })(time)}`}</Text>
}

const renderTimestampDiff = (_, entity) => {
  if (!entity.status.startTime || !entity.status.completionTime || isNilOrEmpty(entity)) return ''
  const duration = durationBetweenDates({
    labels: ['mo', 'd', 'h', 'm', 's'],
    renameLabels: {
      mo: 'month',
      d: 'day',
      h: 'hour',
      m: 'minute',
      s: 'second',
    },
  })(entity.status.startTime, entity.status.completionTime)
  return <Text variant="caption1">{duration}</Text>
}

const renderController = (_, entity) => {
  if (!entity?.metadata?.ownerReferences || isNilOrEmpty(entity)) return ''
  const controller = getJobControllerInfo(entity)
  const name = `${controller?.kind || ''}${
    controller?.kind && controller?.name ? '/' : ''
  }${controller?.name || ''}`
  return (
    <Text variant="caption1">
      <SimpleLink
        src={routes.cronjobs.details.path({
          clusterId: entity.clusterId,
          id: controller.uid,
          tab: ICronjobDetailsPageTabs.Overview,
        })}
      >
        {name}
      </SimpleLink>
    </Text>
  )
}

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  { id: 'selector', title: 'Selector', render: renderResourceLabels({ separator: '=' }) },
  { id: 'spec.parallelism', title: 'Parallelism' },
  {
    id: 'spec.completions',
    title: 'Completions',
  },
  { id: 'id', title: 'Controlled by', render: renderController },
  { id: 'status.startTime', title: 'Started', render: renderAgeFromTimestamp },
  {
    id: 'status.completionTime',
    title: 'Completed',
    render: renderAgeFromTimestamp,
  },
  {
    id: 'status.startTime',
    title: 'Duration',
    render: renderTimestampDiff,
  },
]

const podTemplateMetadataFields = [
  {
    id: 'metadata.labels',
    title: 'Labels',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
]

const volumeTableColumns = [
  { key: 'name', label: 'Name' },
  { key: 'type', label: 'Type', formatFn: getVolumeTypeAndDescription },
  { key: 'hostPath.path', label: 'Path' },
  { key: 'hostPath.type', label: 'Host Path Type' },
]

const JobOverview = ({ job, loading }) => {
  const classes = useStyles()

  const [allConfigMaps, loadingConfigMaps] = useDataLoader(configMapActions.list, {
    clusterId: job?.clusterId,
    namespace: job?.namespace,
  })

  const configMaps = useMemo(() => getConfigMapsForResource(job, allConfigMaps), [
    allConfigMaps,
    job,
  ])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, job)
  }, [job])

  const podTemplateMetadata = useMemo(() => {
    return getFieldsForCard(podTemplateMetadataFields, job?.podTemplate)
  }, [job])

  const renderContainerName = useCallback(
    (name) => (
      <SimpleLink
        src={routes.jobs.container.path({
          clusterId: job?.clusterId,
          id: job?.id,
          containerName: name,
        })}
      >
        {name}
      </SimpleLink>
    ),
    [job],
  )

  const containerColumns = useMemo(
    () => [
      { key: 'name', label: 'Name', render: renderContainerName },
      { key: 'image', label: 'image' },
    ],
    [renderContainerName],
  )

  return (
    <Progress loading={loading}>
      <article className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={<LabelsAndAnnotationsTabs labels={job?.labels} />}
          />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <InfoCard
            items={podTemplateMetadata}
            title="Pod Template"
            footer={
              <Containers
                containers={job?.containers}
                initContainerColumns={containerColumns}
                containerColumns={containerColumns}
                showInitContainers={false}
              />
            }
          />
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={job?.volumes || []}
              columns={volumeTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Taints and Tolerations" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={job?.tolerations || []}
              columns={taintsAndTolerationsListTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          {/*
          <Card title="Conditions" withCustomBody>
            <div>stuff goes here</div>
          </Card> */}
        </div>
      </article>
    </Progress>
  )
}

export default JobOverview
