import { GenericObject } from 'api-client/qbert.model'
import { allKey } from 'app/constants'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { assocPath, complement, flatten, isEmpty, isNil, pathEq, pipe, propEq } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'
import { arrayIfEmpty, emptyArr, emptyObj, filterIf } from 'utils/fp'
import { getK8sDashboardLinkFromVersion } from '../../../infrastructure/components/clusters/action-helpers'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { clustersSelector } from '../../../infrastructure/components/clusters/selectors'
import { getResourcePods } from '../pods/helpers'
import { podsByClusterIdAndNamespaceSelector } from '../pods/selectors'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { durationBetweenDates } from 'utils/misc'
import { IServicesSelector } from 'k8s/components/services/model'
import { qbertEndpointSelector } from 'app/plugins/infrastructure/components/common/selectors'
import { capiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'

const getInternalEndpoints = (name, ports, clusterIPs) => {
  if (!clusterIPs || isEmpty(clusterIPs)) return []
  const allEndpoints = clusterIPs.map((clusterIP) => {
    if (clusterIP === 'None') return []
    const endpoints = ports.map((port) => {
      const ips = [`${clusterIP}:${port.port}`]
      if (port.nodePort) {
        ips.push(`${clusterIP}:${port.port}`)
      }
      return ips
    })
    return endpoints
  })
  return [{ name, endpoints: flatten(allEndpoints) }]
}

interface ExternalEndpoint {
  hostname: string
  ip: string
  ports: Port[]
}

interface Port {
  error: string
  port: number
  protocol: string
}

const getExternalEndpoints = (
  externalIpsList,
  loadBalancerEndpoints,
  externalName,
): ExternalEndpoint[] => {
  const externalIps = externalIpsList.map((ip) => ({ hostname: '', ip, ports: [] }))
  const externalNameEndpoints = externalName ? [{ hostname: externalName, ip: '', ports: [] }] : []
  return [...externalIps, ...externalNameEndpoints, ...loadBalancerEndpoints]
}

export const serviceSelectors = createSharedSelector(
  getDataSelector<DataKeys.KubeServices>(DataKeys.KubeServices, 'clusterId', [
    'clusterId',
    'namespace',
  ]),
  podsByClusterIdAndNamespaceSelector,
  clustersSelector,
  importedClustersSelector,
  capiClustersSelector,
  qbertEndpointSelector,
  (
    rawServices,
    podsByClusterIdAndNamespace,
    clusters,
    importedClusters,
    capiClusters,
    qbertEndpoint,
  ): IServicesSelector[] => {
    return arrayIfEmpty(
      rawServices
        .map((service) => {
          const { clusterId } = service
          const allClusters = [...clusters, ...importedClusters, ...capiClusters]
          const cluster = allClusters.find(propEq('uuid', clusterId))
          if (!cluster) {
            // If no cluster if found, this item is invalid because the parent cluster has been deleted
            return null
          }
          const clusterIp = service?.spec?.clusterIP
          const type = service?.spec?.type // = pathStr('spec.type', service)
          const externalName = service?.spec?.externalName // pathStr('spec.externalName', service)
          const name = service?.metadata?.name // pathStr('metadata.name', service)
          const namespace = service?.metadata?.namespace // pathStr('metadata.namespace', service)
          const ports = service?.spec?.ports || [] // pathStrOr(emptyArr, 'spec.ports', service)
          const loadBalancerEndpoints = service?.status?.loadBalancer?.ingress || emptyArr
          const internalEndpoints = getInternalEndpoints(name, ports, service?.spec?.clusterIPs)
          const externalIps = service?.spec?.externalIPs || []
          const externalEndpoints = getExternalEndpoints(
            externalIps,
            loadBalancerEndpoints,
            externalName,
          )
          const status =
            clusterIp && (type !== 'LoadBalancer' || externalEndpoints.length > 0)
              ? 'OK'
              : 'Pending'

          const k8sDashboardUrl = getK8sDashboardLinkFromVersion(qbertEndpoint, cluster)
          const dashboardUrl = `${k8sDashboardUrl}#/service/${namespace}/${name}?namespace=${namespace}`
          const selectors = service?.spec?.selector || (emptyObj as GenericObject)
          const associatedPods = podsByClusterIdAndNamespace?.[clusterId]?.[namespace] || emptyArr
          const servicePods = getResourcePods(selectors, associatedPods)
          const creationTimestamp = service?.metadata?.creationTimestamp
          return {
            ...service,
            dashboardUrl,
            labels: service?.metadata?.labels, // pathStr('metadata.labels', service)
            annotations: service?.metadata?.annotations,
            selectors,
            type,
            status,
            clusterIp,
            internalEndpoints,
            externalEndpoints,
            namespace,
            clusterName: cluster?.name,
            servicePods,
            ports,
            externalName,
            kind: 'Service',
            creationTimestamp,
            age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          }
        })
        .filter(complement(isNil)),
    )
  },
)

export const makeServiceSelector = (defaultParams = {} as SortConfig & { namespace?: string }) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(serviceSelectors, selectParams, (services, params) => {
    const { namespace, orderBy, orderDirection } = params
    return pipe<IServicesSelector[], IServicesSelector[], IServicesSelector[], IServicesSelector[]>(
      filterIf(namespace && namespace !== allKey, pathEq(['metadata', 'namespace'], namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(services)
  })
}

export const servicesByClusterIdAndNamespaceSelector = createSharedSelector(
  serviceSelectors,
  (rawServices) =>
    rawServices.reduce((accum, service) => {
      const { clusterId } = service
      const namespace = service?.metadata?.namespace
      const existingServices = accum[clusterId]?.[namespace] || []
      const services = [...existingServices, service]
      return assocPath([clusterId, namespace], services, accum)
    }, emptyObj),
)
