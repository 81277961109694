import React, { useCallback, useMemo, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import jsYaml from 'js-yaml'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SubmitButton from 'core/components/buttons/SubmitButton'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import useParams from 'core/hooks/useParams'
import useToggler from 'core/hooks/useToggler'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import GroupMultiSelect from 'k8s/components/common/GroupMultiSelect'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import UserMultiSelect from 'k8s/components/common/UserMultiSelect'
import ServiceAccountPicklist from 'k8s/components/prometheus/ServiceAccountPicklist'
import { IRbacRoleBinding } from 'k8s/components/rbac/model'
import CustomItemsBox from 'k8s/components/rbac/profiles/edit/CustomItemsBox'
import useEditBindingStyles from 'k8s/components/rbac/profiles/edit/useEditBindingsStyles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'k8s/components/rbac/profiles/ProfilesAccordion'
import RolePicklist from 'k8s/components/rbac/RolePicklist'
import moize from 'moize'
import {
  append,
  assocPath,
  complement,
  findIndex,
  isEmpty,
  prop,
  propEq,
  remove,
  update,
  whereEq,
} from 'ramda'
import { except, isNilOrEmpty, stopBubbling } from 'utils/fp'
import { memoize, generateObjMemoizer } from 'utils/misc'

interface Props {
  onClose: () => void
  onSubmit: (roleBinding: IRbacRoleBinding) => void
  open: boolean
  profileName: string
  clusterId
  roleBinding?: IRbacRoleBinding // Only required when editing
}

interface Params {
  clusterId: string
  namespace: string
  role: string
  name: string
  serviceGroups: string[]
  pf9Users: string[]
  pf9Groups: string[]
  externalUsers: string[]
  externalGroups: string[]
}

enum Panels {
  ServiceAccounts,
  Platform9UsersAndGroups,
  ExternalUsersAndGroups,
}

const defaultActivePanels: Panels[] = []

const defaultRoleBinding: IRbacRoleBinding = {
  metadata: {
    name: '',
    namespace: '',
  },
  subjects: [],
  roleRef: {
    kind: '',
    apiGroup: '',
    name: '',
  },
}

const customCodeMirrorOptions = {
  mode: 'json',
}
const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})
const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.namespace must be set'),
  customValidator((yaml) => {
    const { roleRef } = moizedYamlLoad(yaml)
    return (
      !isNilOrEmpty(roleRef) &&
      !isNilOrEmpty(roleRef?.kind) &&
      !isNilOrEmpty(roleRef?.apiGroup) &&
      !isNilOrEmpty(roleRef?.name)
    )
  }, 'roleRef must be set and be valid'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return (
      !isNilOrEmpty(body?.subjects) &&
      body.subjects.every(
        (subject) =>
          !isNilOrEmpty(subject) &&
          !isNilOrEmpty(subject?.kind) &&
          !isNilOrEmpty(subject?.apiGroup) &&
          !isNilOrEmpty(subject?.name),
      )
    )
  }, 'subjects must be set and be valid'),
]

const getMemoizedRoleBinding = generateObjMemoizer<IRbacRoleBinding>()
const EditRoleBindingDialog = ({
  open,
  onClose,
  onSubmit,
  profileName,
  clusterId,
  roleBinding,
}: Props) => {
  const classes = useEditBindingStyles()

  const { params, getParamsUpdater } = useParams({
    clusterId,
  })
  const originalRoleBinding = roleBinding ? getMemoizedRoleBinding(roleBinding) : null
  const [newRoleBinding, updateRoleBinding] = useState<IRbacRoleBinding>(
    originalRoleBinding || defaultRoleBinding,
  )
  const [rawYaml, setRawYaml] = useState()
  const [showingYaml, , setShowingYaml] = useToggler()
  const showYaml = useCallback(() => {
    // Get document, or throw exception on error
    try {
      const rawYaml = jsYaml.dump(newRoleBinding)
      setRawYaml(rawYaml)
      setShowingYaml(true)
    } catch (e) {
      console.error(e)
    }
  }, [newRoleBinding])

  const exitYaml = useCallback(() => {
    const parsedYaml = moizedYamlLoad(rawYaml)
    updateRoleBinding(parsedYaml)
    setShowingYaml(false)
  }, [rawYaml])

  const handleYamlUpdate = useCallback(
    (value) => {
      setRawYaml(value)
    },
    [rawYaml],
  )
  const [activePanels, setActivePanels] = useState<Panels[]>(defaultActivePanels)
  const togglePanel = useCallback(
    (panel) => () => {
      setActivePanels(
        activePanels.includes(panel) ? except(panel, activePanels) : [panel, ...activePanels],
      )
    },
    [activePanels],
  )
  const serviceGroups = useMemo(() => {
    return (newRoleBinding?.subjects.filter(propEq('kind', 'ServiceAccount')) || []).map(
      prop('name'),
    )
  }, [newRoleBinding])

  const addingNew = serviceGroups.some((name) => !name)

  const pf9Users = useMemo(() => {
    return (newRoleBinding?.subjects.filter(propEq('kind', 'User')) || []).map(prop('name'))
  }, [newRoleBinding])

  const pf9Groups = useMemo(() => {
    return (newRoleBinding?.subjects.filter(propEq('kind', 'Group')) || []).map(prop('name'))
  }, [newRoleBinding])

  const externalUsers = useMemo(() => {
    return (newRoleBinding?.subjects.filter(propEq('kind', 'ExternalUser')) || []).map(prop('name'))
  }, [newRoleBinding])

  const externalGroups = useMemo(() => {
    return (newRoleBinding?.subjects.filter(propEq('kind', 'ExternalGroup')) || []).map(
      prop('name'),
    )
  }, [newRoleBinding])

  const role = useMemo(() => {
    return newRoleBinding.roleRef.kind + ':' + newRoleBinding.roleRef.name
  }, [newRoleBinding])

  const addSubject = useCallback(
    (kind, name) => {
      updateRoleBinding({
        ...newRoleBinding,
        subjects: append(
          {
            kind,
            apiGroup: 'rbac.authorization.k8s.io',
            name,
          },
          newRoleBinding.subjects,
        ),
      })
    },
    [newRoleBinding],
  )

  const updateSubjects = useCallback(
    memoize((kind) => (names) => {
      const { subjects } = newRoleBinding
      const filteredSubjects = subjects.filter(complement(propEq('kind', kind)))
      updateRoleBinding({
        ...newRoleBinding,
        subjects: [
          ...filteredSubjects,
          ...names.map((name) => ({
            kind,
            apiGroup: 'rbac.authorization.k8s.io',
            name,
          })),
        ],
      })
    }),
    [newRoleBinding],
  )

  const removeSubject = useCallback(
    (kind, name) => {
      const { subjects } = newRoleBinding
      const idx = findIndex(whereEq({ name, kind }), subjects)
      updateRoleBinding({
        ...newRoleBinding,
        subjects: remove(idx, 1, subjects),
      })
    },
    [newRoleBinding],
  )

  const handleAddServiceGroup = useCallback(
    (name) => {
      addSubject('ServiceAccount', name)
    },
    [newRoleBinding],
  )

  const handleRemoveServiceGroup = useCallback(
    (name) => {
      removeSubject('ServiceAccount', name)
    },
    [newRoleBinding],
  )

  const handleUpdateServiceGroup = useCallback(
    memoize((oldName) => (name) => {
      const { subjects } = newRoleBinding
      const idx = findIndex(whereEq({ name: oldName, kind: 'ServiceAccount' }), subjects)
      updateRoleBinding({
        ...newRoleBinding,
        subjects: update(
          idx,
          {
            kind: 'ServiceAccount',
            apiGroup: 'rbac.authorization.k8s.io',
            name,
          },
          subjects,
        ),
      })
    }),
    [newRoleBinding],
  )

  const handleAddExternalUser = useCallback(
    (name: string) => {
      addSubject('ExternalUser', name)
    },
    [addSubject],
  )

  const handleRemoveExternalUser = useCallback(
    (name: string) => {
      removeSubject('ExternalUser', name)
    },
    [removeSubject],
  )

  const handleAddExternalGroup = useCallback(
    (name: string) => {
      addSubject('ExternalGroup', name)
    },
    [addSubject],
  )

  const handleRemoveExternalGroup = useCallback(
    (name: string) => {
      removeSubject('ExternalGroup', name)
    },
    [removeSubject],
  )

  const handleNameUpdate = useCallback(
    (name) => {
      updateRoleBinding(assocPath(['metadata', 'name'], name, newRoleBinding))
    },
    [newRoleBinding],
  )

  const handleNamespaceUpdate = useCallback(
    (namespace) => {
      updateRoleBinding(assocPath(['metadata', 'namespace'], namespace, newRoleBinding))
    },
    [newRoleBinding],
  )

  const handleRoleUpdate = useCallback(
    (role) => {
      const [roleType, ...rest] = role.split(':')
      const roleName = rest.join(':')
      updateRoleBinding({
        ...newRoleBinding,
        roleRef: {
          kind: roleType,
          apiGroup: 'rbac.authorization.k8s.io',
          name: roleName,
        },
      })
    },
    [newRoleBinding],
  )

  const handleSubmit = useCallback(() => {
    onSubmit(newRoleBinding)
  }, [newRoleBinding])

  const defaultView = (
    <ValidatedForm
      classes={{ formActions: classes.formActions }}
      onSubmit={handleSubmit}
      title="Edit Group"
      elevated={false}
      formActions={
        <DialogActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <SubmitButton color="primary">Save</SubmitButton>
        </DialogActions>
      }
    >
      <div className={classes.policyDetails}>
        <header className={classes.header}>
          <Text variant="body1">{roleBinding ? 'Edit' : 'Add New'} Role Binding</Text>
          <Button onClick={showYaml} className={classes.yamlBtn} variant="secondary">
            YAML View
          </Button>{' '}
        </header>
        <Text variant="body2">
          Role bindings edited within the rbac profile will not affect the role bindings selected
          during profile creation.
        </Text>
        <div className={classes.mainSelectors}>
          <Text variant="body1">
            Profile Name: <strong>{profileName}</strong>
          </Text>
          <TextField
            value={newRoleBinding?.metadata?.name}
            onChange={handleNameUpdate}
            required
            id="name"
            label="Role Binding Name"
          />
          <PicklistField
            DropdownComponent={ClusterPicklist}
            disabled={!params.clusterId}
            id="clusterId"
            label="Cluster"
            onChange={getParamsUpdater('clusterId')}
            selectFirst
            showAll={false}
            compact={false}
          />
          {/* When using PicklistField, initialValue does not get passed along to dropdown component */}
          <NamespacePicklist
            onChange={handleNamespaceUpdate}
            value={newRoleBinding?.metadata?.namespace}
            initialValue={originalRoleBinding?.metadata?.namespace}
            clusterId={params.clusterId}
            selectFirst
            showAll={false}
            disabled={!params.clusterId}
            compact={false}
            setInitialNamespace={!originalRoleBinding}
          />
          <PicklistField
            DropdownComponent={RolePicklist}
            disabled={!params.clusterId}
            id="role"
            label="Role"
            clusterId={params.clusterId}
            namespace={newRoleBinding?.metadata?.namespace}
            onChange={handleRoleUpdate}
            value={role}
            showAllRoleTypes
            selectFirst={false}
            required
          />
        </div>
      </div>
      <hr />
      <Accordion
        expanded={activePanels.includes(Panels.ServiceAccounts)}
        onClick={togglePanel(Panels.ServiceAccounts)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.accordionTitle}>
            {`Add Service Accounts`}{' '}
            <span>{`(${serviceGroups.filter(complement(isEmpty)).length} added)`})</span>
          </div>
        </AccordionSummary>
        <AccordionDetails onClick={stopBubbling}>
          {serviceGroups.map((serviceGroup, idx) => (
            <div className={classes.serviceAccount} key={idx}>
              <PicklistField
                DropdownComponent={ServiceAccountPicklist}
                id={`serviceGroup[${idx}]`}
                label="Service Groups"
                disabled={!params.clusterId || !newRoleBinding?.metadata?.namespace}
                clusterId={params.clusterId}
                namespace={newRoleBinding?.metadata?.namespace}
                onChange={handleUpdateServiceGroup(serviceGroup)}
                value={serviceGroup}
              />
              <FontAwesomeIcon
                className={classes.removeServiceAccountIcon}
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemoveServiceGroup(serviceGroup)
                }}
                size="md"
                solid
              >
                minus-circle
              </FontAwesomeIcon>
            </div>
          ))}
          {!addingNew && (
            <div className={classes.addServiceBtn} onClick={() => handleAddServiceGroup('')}>
              <FontAwesomeIcon className={classes.plusIcon} size="md">
                plus-circle
              </FontAwesomeIcon>
              Add a new Service Group
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={activePanels.includes(Panels.Platform9UsersAndGroups)}
        onClick={togglePanel(Panels.Platform9UsersAndGroups)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.accordionTitle}>
            {`Add Platform9 Users & Groups`}{' '}
            <span>{`(${pf9Users.length} Users, ${pf9Groups.length} Groups added)`}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails onClick={stopBubbling}>
          <div className={classes.splitContainer}>
            <UserMultiSelect
              id="pf9Users"
              tooltip="Select users to assign this role"
              onChange={updateSubjects('User')}
              value={pf9Users}
            />
            <GroupMultiSelect
              id="pf9Groups"
              tooltip="Select groups to assign this role"
              onChange={updateSubjects('Group')}
              value={pf9Groups}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={activePanels.includes(Panels.ExternalUsersAndGroups)}
        onClick={togglePanel(Panels.ExternalUsersAndGroups)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.accordionTitle}>
            {`Add External Users & Groups`}{' '}
            <span>{`(${externalUsers.length} Users, ${externalGroups.length} Groups added)`}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails onClick={stopBubbling}>
          <div className={classes.splitContainer}>
            <CustomItemsBox
              id="users"
              label="Users"
              addNewLabel="Add New User"
              items={externalUsers}
              onAddItem={handleAddExternalUser}
              onRemoveItem={handleRemoveExternalUser}
            />
            <CustomItemsBox
              id="groups"
              label="Groups"
              addNewLabel="Add New Group"
              items={externalGroups}
              onAddItem={handleAddExternalGroup}
              onRemoveItem={handleRemoveExternalGroup}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </ValidatedForm>
  )

  const yamlView = (
    <ValidatedForm onSubmit={exitYaml}>
      <div className={classes.filters}>
        <Text variant="body1">Enter value details below or upload a YAML file</Text>
        <SubmitButton className={classes.yamlBtn} variant="secondary">
          Exit YAML View
        </SubmitButton>
      </div>
      <CodeMirror
        id="yamlView"
        label="YAML RESOURCE"
        options={customCodeMirrorOptions}
        onChange={handleYamlUpdate}
        value={rawYaml}
        className={classes.codeMirror}
        validations={codeMirrorValidations}
        required
      />
    </ValidatedForm>
  )

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>{showingYaml ? yamlView : defaultView}</DialogContent>
    </Dialog>
  )
}

export default EditRoleBindingDialog
