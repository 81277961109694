import React from 'react'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import { makeStyles } from '@material-ui/styles'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import { formatDate } from 'utils/misc'
import { SeverityTableCell } from './AlarmsListPage'
import { IAlertSelector } from './model'
import renderLabels from '../pods/renderLabels'
import Modal from 'core/elements/modal'

interface Props {
  alarm: IAlertSelector
  onClose: (e) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  upperBody: {
    paddingBottom: 16,
    borderBottom: `1px solid ${theme.components.tab.border}`,
  },
  lowerBody: {
    wordBreak: 'break-word',
  },
}))

const AlarmDetailsDialog = ({ alarm, onClose }: Props) => {
  const classes = useStyles({})

  const upperValues = [
    { key: 'Event Time', value: formatDate(alarm.updatedAt) },
    {
      key: 'Alarm Severity',
      value: alarm.severity,
      render: (value) => <SeverityTableCell value={value} />,
    },
    { key: 'Alarm Summary', value: alarm.summary },
  ]
  const lowerValues = [
    {
      key: 'Labels',
      value: <div>{renderLabels('labels', 'body2')(alarm?.labels)}</div>,
    },
    { key: 'Conditions', value: alarm.query },
    { key: 'Duration', value: alarm.for || 'N/A' },
  ]

  return (
    <Modal
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      onClose={onClose}
      title={alarm.name}
    >
      <div className={classes.upperBody}>
        <DisplayKeyValues keyValuePairs={upperValues} />
      </div>
      <div className={classes.lowerBody}>
        <DisplayKeyValues keyValuePairs={lowerValues} rowSpacing={24} />
      </div>
    </Modal>
  )
}

export default AlarmDetailsDialog
