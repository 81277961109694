import { routes } from 'core/utils/routes'
import {
  getContainerCpuUsage,
  getContainerMemoryUsage,
  getContainerStatus,
} from 'k8s/components/pods/helpers'
import {
  createResourceAnnotationsCell,
  createResourceLabelsCell,
} from './labels-and-annotations/helpers'
import { getPvcStatus } from '../../storage/persistent-volume-claims/helpers'
import renderLabels from '../../pods/renderLabels'
import { renderLabelsInOneRow } from './labels-and-annotations/LabelsInOneRow'
import ConfigMapDataDetailsLink from 'k8s/components/config-maps/data-details-link'
import { getTaintsAndTolerationsMessage } from 'k8s/components/pods/view-helpers'

import GridStatusMessageCell from 'core/elements/grid/cells/GridStatusMessageCell'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { createGridArrayCell } from 'core/elements/grid/cells/GridArrayCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { IServicesSelector } from 'k8s/components/services/model'
import {
  IPersistentVolumeClaimsSelector,
  PodsMounted,
} from 'k8s/components/storage/persistent-volume-claims/model'
import { IContainer } from 'k8s/components/pods/model'

export const selectorFields = [
  {
    id: 'selectors',
    title: '',
    required: true,
    CellComponent: createResourceLabelsCell({ separator: '=', variant: 'default' }),
  },
]

export const detailsFields = {
  left: [
    {
      id: 'metadata.labels',
      title: 'Labels',
      required: true,
      CellComponent: createResourceLabelsCell({}),
    },
  ],
  right: [
    {
      id: 'metadata.annotations',
      title: 'Annotations',
      required: true,
      CellComponent: createResourceAnnotationsCell({}),
    },
  ],
}

export const containerColumns: GridViewColumn<IContainer>[] = [
  {
    key: 'name',
    label: 'Name',
    CellComponent: createGridLinkCell({
      routeToFn: ({ name, clusterId, podId }) =>
        routes.pods.container.details.path({
          clusterId,
          id: podId,
          containerName: name,
        }),
    }),
  },
  { key: 'restartCount', label: 'Restarts' },
  { key: 'usage.cpu', label: 'CPU Usage', formatFn: getContainerCpuUsage },
  { key: 'usage.memory', label: 'Mem Usage', formatFn: getContainerMemoryUsage },
  {
    key: 'status.state',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getContainerStatus,
    }),
  },
  { key: 'status', label: 'Status Message', CellComponent: GridStatusMessageCell },
]

export const serviceTableColumns: GridViewColumn<IServicesSelector>[] = [
  {
    key: 'name',
    label: 'Name',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id }) => routes.services.details.path({ clusterId, id }),
    }),
  },
  { key: 'type', label: 'Type' },
  { key: 'clusterIp', label: 'Cluster IP' },
  {
    key: 'ports',
    label: 'Ports',
    CellComponent: createGridArrayCell({
      nameFn: ({ protocol, port }) => `${port}/${protocol}`,
    }),
  },
]

export const ingressTableColumns = [
  { key: 'name', label: 'Name' },
  {
    key: 'defaultBackend',
    label: 'Default Backend',
    render: renderLabels('label'),
  },
  {
    key: 'rules',
    label: 'Rules',
    render: (rules) => rules.map((rule) => renderLabelsInOneRow(rule)),
  },
]

export const configMapColumns = [
  { key: 'name', label: 'Name', CellComponent: ConfigMapDataDetailsLink, memoizeCell: false },
]

export const persistentVolumeClaimsListTableColumns: GridViewColumn<
  IPersistentVolumeClaimsSelector
>[] = [
  {
    key: 'name',
    label: 'Name',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, storageClass }) =>
        !storageClass?.metadata?.uid || !clusterId
          ? null
          : routes.storage.storageClasses.details.path({
              clusterId,
              id: storageClass.metadata.uid,
            }),
    }),
  },
  {
    key: 'status.phase',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getPvcStatus,
    }),
  },
  {
    key: 'podsMounted',
    label: 'Mounted By',
    render: (podsMounted: PodsMounted[]) => podsMounted.map((pod) => renderLabelsInOneRow(pod)),
  },
  { key: 'capacity', label: 'Capacity', CellComponent: createResourceLabelsCell({}) },
  { key: 'storageClassName', label: 'Storage Class' },
]

export const taintsAndTolerationsListTableColumns = [
  { key: 'taintsAndTolerations', label: 'Description', formatFn: getTaintsAndTolerationsMessage },
]
