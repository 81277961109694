import React, { useMemo, useCallback } from 'react'
import InfoCard from '../common/entity/info-card'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import LabelsAndAnnotationsTabs from '../common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Progress from 'core/components/progress/Progress'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Containers from '../common/entity/containers'
import Card from 'core/elements/card'
import { renderResourceLabels } from '../common/entity/labels-and-annotations/helpers'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import useDataLoader from 'core/hooks/useDataLoader'
import { getConfigMapsForResource } from '../config-maps/helpers'
import { configMapActions } from '../config-maps/actions'
import { configMapColumns, taintsAndTolerationsListTableColumns } from '../common/entity/constants'
import { getVolumeTypeAndDescription } from '../storage/helpers'
import { renderAge } from '../common/entity/helpers'
import Grid from 'core/elements/grid'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
}))

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'numberReady', title: 'Ready', required: true },
  {
    id: 'selector',
    title: 'Selector',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  {
    id: 'nodeSelector',
    title: 'Node Selector',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  { id: 'desiredNumberScheduled', title: 'Desired Nodes Scheduled', required: true },
  { id: 'currentNumberScheduled', title: 'Current Nodes Scheduled', required: true },
  { id: 'updatedNumberScheduled', title: 'Nodes Scheduled with Up-to-date Pods', required: true },
  { id: 'numberAvailable', title: 'Nodes Scheduled with Available Pods', required: true },
  { id: 'numberMisscheduled', title: 'Nodes Misscheduled', required: true },
  {
    id: 'age',
    title: 'Age',
    required: true,
    render: renderAge,
  },
]

const podTemplateMetadataFields = [
  {
    id: 'metadata.labels',
    title: 'Labels',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  {
    id: 'spec.serviceAccountName',
    title: 'Service Account',
    required: true,
  },
]

const volumeTableColumns = [
  { key: 'name', label: 'Name' },
  { key: 'type', label: 'Type', formatFn: getVolumeTypeAndDescription },
  { key: 'hostPath.path', label: 'Path' },
  { key: 'hostpath.type', label: 'Host Path Type' },
]

const DaemonSetOverview = ({ daemonSet, loading }) => {
  const classes = useStyles()

  const [allConfigMaps, loadingConfigMaps] = useDataLoader(configMapActions.list, {
    clusterId: daemonSet?.clusterId,
    namespace: daemonSet?.namespace,
  })

  const configMaps = useMemo(() => getConfigMapsForResource(daemonSet, allConfigMaps), [
    allConfigMaps,
    daemonSet,
  ])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, daemonSet)
  }, [daemonSet])

  const podTemplateMetadata = useMemo(() => {
    return getFieldsForCard(podTemplateMetadataFields, daemonSet?.podTemplate)
  }, [daemonSet])

  const renderContainerName = useCallback(
    (name) => (
      <SimpleLink
        src={routes.daemonSets.container.path({
          clusterId: daemonSet?.clusterId,
          id: daemonSet?.id,
          containerName: name,
        })}
      >
        {name}
      </SimpleLink>
    ),
    [daemonSet],
  )

  const containerColumns = useMemo(
    () => [
      { key: 'name', label: 'Name', render: renderContainerName },
      { key: 'image', label: 'image' },
    ],
    [renderContainerName],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={
              <LabelsAndAnnotationsTabs
                labels={daemonSet?.labels}
                annotations={daemonSet?.annotations}
              />
            }
          />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <InfoCard
            items={podTemplateMetadata}
            title="Pod Template"
            footer={
              <Containers
                initContainers={daemonSet?.initContainers}
                containers={daemonSet?.containers}
                initContainerColumns={containerColumns}
                containerColumns={containerColumns}
              />
            }
          />
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={daemonSet?.volumes}
              columns={volumeTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Taints and Tolerations" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={daemonSet?.tolerations}
              columns={taintsAndTolerationsListTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

export default DaemonSetOverview
