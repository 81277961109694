import React from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import DataKeys from 'k8s/DataKeys'
import {
  renderResourceLabels,
  renderLabelsAsBadges,
} from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { renderPvcStatus } from './helpers'
import DateCell, { DateAndTime } from 'core/components/listTable/cells/DateCell'
import DocumentMeta from 'core/components/DocumentMeta'
import { persistentVolumeClaimsSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { listPersistentVolumeClaims } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import ListContainer from 'core/containers/ListContainer'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ModelDataKey = DataKeys.PersistentVolumeClaims
type SelectorModel = ArrayElement<ReturnType<typeof persistentVolumeClaimsSelector>>
type ActionParams = InferActionParams<typeof listPersistentVolumeClaims>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'PersistentVolumeClaims',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  } as GridViewColumn<SelectorModel, 'name'>,
  { key: 'namespace', label: 'Namespace' }, // had display: false before
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  { key: 'status.phase', label: 'Status', render: renderPvcStatus },
  {
    key: 'labels',
    label: 'Label',
    render: renderResourceLabels({ type: 'table', variant: 'default' }),
  },
  { key: 'volume', label: 'Volume' },
  { key: 'capacity', label: 'Capacity', render: renderResourceLabels({ type: 'table' }) },
  {
    key: 'accessModes',
    label: 'Access Modes',
    render: renderLabelsAsBadges({ variant: 'default' }),
  },
  { key: 'storageClassName', label: 'Storage Class' },
  { key: 'created', label: 'Created', render: (value: string) => <DateAndTime value={value} /> },
]

export default function PersistentVolumeClaimsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams,
  })
  const data = useAppSelector(persistentVolumeClaimsSelector)

  return (
    <>
      <DocumentMeta title="Persistent Volume Claims" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.PersistentVolumeClaims}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const toFilterColumns = {
  clusterName: true,
  labels: true,
  volume: true,
  storageClass: true,
  created: true,
}

const formatOpts = {
  renameLabels: {},
  labels: ['d', 'h'],
  pluralize: false,
}

export const pvcDetailOptions = {
  title: 'Persistent Volume Claims',
  columns: [
    ...columns.filter((column) => !toFilterColumns[column.key]),
    {
      key: 'created',
      label: 'Age',
      render: (value) => <DateCell value={value} formatOpts={formatOpts} showToolTip difference />,
    },
  ],
  columnsOrder: ['name', 'created', 'capacity', 'accessModes', 'status.phase'],
}
