import React, { useMemo } from 'react'
import SeverityPicklist from 'k8s/components/alarms/SeverityPicklist'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import RuleDetailsLink from './RuleDetailsLink'
import { getAlarmSeverityStatus } from 'k8s/components/alarms/AlarmsListPage'
import DataKeys from 'k8s/DataKeys'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { ArrayElement } from 'core/actions/Action'
import { alertRulesSelector } from './selectors'
import { listAlertRules } from './new-actions'
import { useSelector } from 'react-redux'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ModelDataKey = DataKeys.AlertRules
type SelectorModel = ArrayElement<ReturnType<typeof alertRulesSelector>>
type ActionParams = InferActionParams<typeof listAlertRules>

type Params = ActionParams & {
  severity?: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  severity: allKey,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Rules', listTablePrefs)

const searchTargets = ['name', 'clusterName']

const NameCell = ({ value, item }) => <RuleDetailsLink display={value} rule={item} />

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NameCell,
  },
  {
    key: 'severity',
    label: 'Severity',
    CellComponent: createGridStatusCell({
      dataFn: getAlarmSeverityStatus,
    }),
  },
  { key: 'summary', label: 'Summary' },
  { key: 'description', label: 'Description' },
  { key: 'query', label: 'Expressions' },
  { key: 'for', label: 'Duration' },
  { key: 'clusterName', label: 'Cluster' },
]

export default function RulesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  const { message, loading, reload } = useListAction(listAlertRules, {
    params,
    requiredParams,
  })
  const rules = useAppSelector(alertRulesSelector)

  const filteredRules = rules.filter((alert) => {
    return [allKey, alert.severity].includes(params.severity)
  })

  const filters = useMemo(
    () => [
      {
        columnKey: 'severity',
        FilterComponent: SeverityPicklist,
        controlled: true,
        onChange: getParamsUpdater('severity'),
        // @ts-ignore selector model not in place yet
      } as GridFilterSpec<SelectorModel, Params, 'severity'>,
    ],
    [],
  )

  return (
    <>
      <DocumentMeta title="Rules" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.AlertRules}
        searchTargets={searchTargets}
        // @ts-ignore selector model not in place yet
        uniqueIdentifier="id"
        // @ts-ignore selector model not in place yet
        filters={filters}
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={filteredRules}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        // Uncomment when custom label is available
        // label="Rules"
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
