import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import DataKeys from 'k8s/DataKeys'
import { makeConfigMapsSelector } from './selectors'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

export const configMapActions = createCRUDActions(DataKeys.ConfigMaps, {
  listFn: async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Config Maps', params)
    const clusterIds = parseClusterIdsFromParams(params)
    return someAsync(clusterIds.map(qbert.getConfigMaps)).then(flatten)
  },
  service: 'qbert',
  entity: DataKeys.ConfigMaps,
  indexBy: ['clusterId'],
  selectorCreator: makeConfigMapsSelector,
})
