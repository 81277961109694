import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import PodOverview from './overview'
import PodLogsPage from './logs-page'
import EntityEventsPage from '../common/entity/entity-events-page'
import EntityYamlPage from '../common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { IPodDetailsPageTabs, IPodSelector } from './model'
import InfoHeader from '../common/entity/info-header'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listPods, updatePod } from './new-actions'
import { podsSelector } from './selectors'

const { qbert } = ApiClient.getInstance()

const defaultPod = {
  containers: [],
} as IPodSelector

const PodOverviewPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, namespace } = match.params
  const { loading } = useListAction(listPods, { params: { clusterId, namespace } })
  const pods = useSelectorWithParams(podsSelector, { clusterId, useGlobalParams: false })
  const pod = useMemo(() => pods.find((pod) => pod.id === id) || defaultPod, [id, pods])
  const { update, updating } = useUpdateAction(updatePod)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: pod?.clusterName || clusterId,
        id: pod?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [pod?.clusterName, pod?.name, id, clusterId])

  const getPodYaml = useCallback(async () => {
    if (isEmpty(pod)) return undefined
    return qbert.getClusterPod(pod.clusterId, pod.namespace, pod.name)
  }, [pod])

  const handlePodUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({
        id,
        clusterId,
        namespace,
        name,
        body,
      })
    },
    [pod],
  )

  const headerColumns = useMemo(() => {
    if (isEmpty(pod)) return []
    return [
      { label: 'Name', value: pod?.name },
      { label: 'Cluster', value: pod?.clusterName },
      { label: 'QoS', value: pod?.qosClass },
      { label: 'Age', value: pod?.age },
    ]
  }, [pod])

  return (
    <>
      <DocumentMeta title="Pod Overview" breadcrumbs />
      <InfoHeader columns={headerColumns} />
      <Tabs route={routes.pods.details}>
        <Tab label="Overview" value={IPodDetailsPageTabs.Overview}>
          <PodOverview pod={pod} loading={loading} />
        </Tab>
        <Tab label="Events" value={IPodDetailsPageTabs.Events}>
          <EntityEventsPage entity={pod} loading={loading} />
        </Tab>
        <Tab label="Logs" value={IPodDetailsPageTabs.Logs}>
          <PodLogsPage pod={pod} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IPodDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Pod"
            entityName={pod?.name}
            getYamlFn={getPodYaml}
            handleUpdate={handlePodUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default PodOverviewPage
