import ConfirmationDialog from 'core/components/ConfirmationDialog'
import React, { useMemo } from 'react'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { UserPreferences } from 'app/constants'
import { emptyObj } from 'utils/fp'
import { omit } from 'ramda'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteNamespace as deleteNamespaceAction } from './new-actions'

const isDefaultNamespace = (defaultNamespaces, clusterId, namespaceName) =>
  defaultNamespaces?.[clusterId]?.namespace === namespaceName

const DeleteNamespaceDialog = ({ rows, onClose }) => {
  const { update: deleteNamespace, updating: deletingNamespace } = useUpdateAction(
    deleteNamespaceAction,
  )
  const { prefs, updateUserDefaults } = useScopedPreferences('defaults')

  const defaultNamespaces = prefs[UserPreferences.Namespace] || emptyObj

  const deleteConfirmText = useMemo(() => getDeleteConfirmText(rows), [rows])

  const handleDelete = async () => {
    for (const namespace of rows) {
      const { success } = await deleteNamespace(namespace)
      if (success && isDefaultNamespace(defaultNamespaces, namespace.clusterId, namespace.name)) {
        updateUserDefaults(
          UserPreferences.Namespace,
          omit([namespace.clusterId], defaultNamespaces),
          true,
        )
      }
    }
    onClose()
  }

  return (
    <ConfirmationDialog
      open
      text={deleteConfirmText}
      onCancel={onClose}
      onConfirm={handleDelete}
      loading={deletingNamespace}
    />
  )
}

export default DeleteNamespaceDialog
