import { allKey } from 'app/constants'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { pipe, propEq } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'
import { arrayIfEmpty, filterIf } from 'utils/fp'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'

export const configMapsSelector = createSharedSelector(
  getDataSelector<DataKeys.ConfigMaps>(DataKeys.ConfigMaps),
  (rawConfigMaps) => {
    return rawConfigMaps
  },
)

export const makeConfigMapsSelector = (
  defaultParams = {} as SortConfig & { namespace?: string; clusterId?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(
    getDataSelector<DataKeys.ConfigMaps>(DataKeys.ConfigMaps),
    selectParams,
    (configMaps, params) => {
      const { clusterId, namespace, orderBy, orderDirection } = params
      return pipe<any, any, any, any, any>(
        filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
        filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
        createSorter({ orderBy, orderDirection }),
        arrayIfEmpty,
      )(configMaps)
    },
  )
}
