import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import {
  configMapColumns,
  ingressTableColumns,
  containerColumns,
  serviceTableColumns,
  persistentVolumeClaimsListTableColumns,
} from '../common/entity/constants'
import { getConfigMapsForResource } from '../config-maps/helpers'
import { getResourceIngresses } from '../ingresses/helpers'
import { getServicesForResource } from '../services/helpers'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from '../common/entity/info-card'
import LabelsAndAnnotationsTabs from '../common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Progress from 'core/components/progress/Progress'
import { renderAge } from '../common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from '../storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from '../storage/persistent-volume-claims/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listIngresses } from '../ingresses/new-actions'
import { ingressesSelector } from '../ingresses/selectors'
import { listConfigMaps } from '../config-maps/new-actions'
import { configMapsSelector } from '../config-maps/selectors'
import { listServices } from '../services/new-actions'
import { serviceSelectors } from '../services/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
}))

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  { id: 'qosClass', title: 'QoS', required: true },
  { id: 'status.hostIP', title: 'Node IP', required: true },
  {
    id: 'age',
    title: 'Age',
    required: true,
    render: renderAge,
  },
]

const pvcSelector = makePersistentVolumeClaimSelector()

const PodOverview = ({ pod, loading }) => {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: pod?.clusterId,
    }),
    [pod],
  )

  const { loading: loadingServices } = useListAction(listServices, {
    params,
    requiredParams: ['clusterId'],
  })
  const allServices = useSelectorWithParams(serviceSelectors, params)
  const services = useMemo(() => getServicesForResource(pod, allServices), [pod, allServices])

  const { loading: loadingIngresses } = useListAction(listIngresses, {
    params,
    requiredParams: ['clusterId'],
  })
  const allIngresses = useSelectorWithParams(ingressesSelector, params)
  const ingresses = useMemo(() => getResourceIngresses(services, allIngresses), [
    allIngresses,
    services,
  ])

  const { loading: loadingConfigMaps } = useListAction(listConfigMaps, {
    params,
    requiredParams: ['clusterId'],
  })
  const allConfigMaps = useSelectorWithParams(configMapsSelector, params)
  const configMaps = useMemo(() => getConfigMapsForResource(pod, allConfigMaps), [
    allConfigMaps,
    pod,
  ])

  const { loading: loadingPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const allPvcs = useSelectorWithParams(pvcSelector, params)
  const pvcs = useMemo(() => {
    return allPvcs.filter((pvc) => {
      const mounts = pvc.podsMounted || []
      return !!mounts.find((m) => m?.pod === pod?.name)
    })
  }, [allPvcs, pod])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, pod)
  }, [pod])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={
              <LabelsAndAnnotationsTabs labels={pod?.labels} annotations={pod?.annotations} />
            }
          />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Containers" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={pod?.containers}
              columns={containerColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Services" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={services}
              columns={serviceTableColumns}
              loading={loadingServices}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Ingresses" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={ingresses}
              columns={ingressTableColumns}
              loading={loadingIngresses || loadingServices}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Persistent Volume Claims" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={pvcs}
              columns={persistentVolumeClaimsListTableColumns}
              loading={loadingPvcs}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
export default PodOverview
