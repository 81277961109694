import React, { forwardRef, useMemo, useEffect } from 'react'
import PicklistDefault from 'core/elements/dropdown/AsyncDropdown'
import { loadSupportedRoleVersions } from '../../../infrastructure/components/clusters/actions'
import useDataLoader from 'core/hooks/useDataLoader'
import { projectAs } from 'utils/fp'
import { pluck } from 'ramda'

const Picklist: any = PicklistDefault // types on forward ref .js file dont work well.

interface Props {
  onChange: (key: string) => void
  value: string
  selectFirst?: boolean
}

const KubernetesVersionPicklist = forwardRef(
  ({ onChange, value, selectFirst, ...rest }: Props, ref) => {
    const [roles, loading] = useDataLoader(loadSupportedRoleVersions)
    const options = useMemo(() => {
      return projectAs({ value: 'roleVersion', label: 'roleVersion' }, roles)
    }, [roles])

    const versions: string[] = useMemo(() => pluck('roleVersion')(roles), [roles])
    useEffect(() => {
      if (selectFirst && versions?.length) {
        if (!value || !versions.includes(value)) {
          const roleVersion = versions.reduce((acc, version) => (acc > version ? acc : version), '') // get the newest (largest value) version
          onChange(roleVersion)
        }
      }
    }, [roles, selectFirst, value])

    return (
      <Picklist
        {...rest}
        value={value}
        onChange={onChange}
        loading={loading}
        ref={ref}
        items={options}
      />
    )
  },
)

export default KubernetesVersionPicklist
