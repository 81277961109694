import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

export const persistentVolumeActions = ActionsSet.make<DataKeys.PersistentVolumes>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.PersistentVolumes],
  cacheKey: DataKeys.PersistentVolumes,
})

export const listPersistentVolumes = persistentVolumeActions.add(
  new ListAction<DataKeys.PersistentVolumes, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Persistent Volumes', params)
    return qbert.getPersistentVolumes(params.clusterId)
  }),
)
