import Plugin from 'core/plugins/plugin'
import { AppPlugins } from 'app/constants'
import { isDecco } from 'core/utils/helpers'

import NotificationsPage from 'core/components/notifications/NotificationsPage'
// import AlarmsListPage from 'k8s/components/alarms/AlarmsListPage'
import AppCatalogPage from 'k8s/components/app-catalog/apps/components/AppCatalogPage'
import DeployedAppsPage from 'k8s/components/app-catalog/deployed-apps/components/DeployedAppsPage'
import DeployedAppDetailsPage from 'k8s/components/app-catalog/deployed-apps/components/details/DeployedAppDetailsPage'
import RepositoriesListPage from 'k8s/components/app-catalog/repositories/components/RepositoriesListPage'
import HelpPage from 'k8s/components/common/HelpPage'
import CreateRbacProfile from 'k8s/components/rbac/profiles/create'
import ApiServicesPage from './components/apiAccess/ApiServicesPage'
import KubeConfigListPage from './components/apiAccess/kubeConfig/KubeConfigListPage'
import TerraformListPage from './components/apiAccess/TerraformListPage'
import CronjobContainerDetailsPage from './components/cronjobs/cronjob-details/CronjobContainerDetailsPage'
import CronjobOverviewPage from './components/cronjobs/cronjob-details/CronjobOverviewPage'
import CronjobsListPage from './components/cronjobs/CronjobsListPage'
import JobContainerDetailsPage from './components/cronjobs/job-details/JobContainerDetailsPage'
import JobOverviewPage from './components/cronjobs/job-details/JobOverviewPage'
import DaemonSetContainerDetailsPage from './components/daemon-sets/DaemonSetContainerDetailsPage'
import DaemonSetOverviewPage from './components/daemon-sets/DaemonSetOverviewPage'
import DaemonSetsListPage from './components/daemon-sets/DaemonSetsListPage'

import DashboardPage from './components/dashboard/dashboard-page'
import EditDashboardPage from './components/dashboard/edit'
import DeploymentOverviewPage from './components/deployments'
import DeploymentsListPage from './components/deployments/DeploymentsListPage'

import AlarmChartListPage from './components/monitoring/AlarmChartListPage'

import AlarmOverviewPage from './components/monitoring/AlarmOverviewPage'
import RulesListPage from './components/monitoring/RulesListPage'
import NamespacesListPage from './components/namespaces/NamespacesListPage'
import OnboardingPage from './components/onboarding/onboarding-page'
import PodOverviewPage from './components/pods'
import PodContainerDetailsPage from './components/pods/pod-container-details-page'
import PodsListPage from './components/pods/PodsListPage'
import ClusterRoleBindingsListPage from './components/rbac/ClusterRoleBindingsListPage'
import ClusterRolesListPage from './components/rbac/ClusterRolesListPage'
import RoleBindingsListPage from './components/rbac/RoleBindingsListPage'

import RolesListPage from './components/rbac/RolesListPage'
import ReplicaSetsContainerDetailsPage from './components/replica-sets/container-details.page'
import ReplicaSetOverviewPage from './components/replica-sets/ReplicaSetOverviewPage'
import ReplicaSetsListPage from './components/replica-sets/ReplicaSetsListPage'
import ServiceOverviewPage from './components/services'
import ServicesListPage from './components/services/ServicesListPage'
import StatefulSetsContainerDetailsPage from './components/stateful-sets/container-details-page'
import StatefulSetOverviewPage from './components/stateful-sets/index'

import StatefulSetsListPage from './components/stateful-sets/StatefulSetsListPage'
import CSIDriversListPage from './components/storage/csi-drivers/CsiDriversListPage'
import StorageDetailsPage from './components/storage/details/StorageClassDetailsPage'
import PersistentVolumeClaimsListPage from './components/storage/persistent-volume-claims/PersistentVolumeClaimsListPage'
import PersistentVolumesListPage from './components/storage/persistent-volume/PersistentVolumesListPage'
import StorageClassListPage from './components/storage/storage-classes/StorageClassesListPage'
import StorageProvidersListPage from './components/storage/storage-providers/StorageProvidersListPage'
import { kubernetesLogo } from './logo'

const kubernetesPlugin = new Plugin(
  AppPlugins.Kubernetes,
  'Kubernetes',
  '/ui/kubernetes',
  kubernetesLogo,
)

kubernetesPlugin.registerRoutes([
  {
    name: 'Dashboard',
    link: { path: '/dashboard/overview', exact: true, default: true },
    component: DashboardPage,
  },
  {
    name: 'Alarms',
    link: { path: '/dashboard/alarms', exact: true },
    component: AlarmOverviewPage,
  },
  {
    name: 'Edit Dashboard',
    link: { path: '/dashboard/overview/edit', exact: true },
    component: EditDashboardPage,
  },
  {
    name: 'Notifications',
    link: { path: '/notifications/:notificationType/:id?', exact: true },
    component: NotificationsPage,
  },
  {
    name: 'App Catalog',
    link: { path: '/apps/app-catalog/(deploy)?/:repository?/:name?', exact: true },
    requiredFeatures: isDecco,
    component: AppCatalogPage,
  },
  {
    name: 'Deployed Apps',
    link: {
      path: '/apps/deployed-apps',
      exact: true,
    },
    requiredFeatures: isDecco,
    component: DeployedAppsPage,
  },
  {
    name: 'Deployed App Details',
    link: {
      path: '/apps/:clusterId/:namespace/deployed-apps/(edit)?/:repository/:chart/:name/:tab?',
      exact: true,
    },
    requiredFeatures: isDecco,
    component: DeployedAppDetailsPage,
  },
  {
    name: 'Repositories',
    link: { path: '/apps/repositories/(add||edit)?/:id?', exact: true },
    requiredFeatures: isDecco,
    component: RepositoriesListPage,
  },
  {
    name: 'Pods',
    link: { path: '/workloads/pods/(add)?', exact: true },
    component: PodsListPage,
  },
  {
    name: 'Pod Details',
    link: { path: '/workloads/clusters/:clusterId/pods/:id/:tab?', exact: true },
    component: PodOverviewPage,
  },
  {
    name: 'Pod Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/pods/:id/containers/:containerName',
      exact: true,
    },
    component: PodContainerDetailsPage,
  },
  {
    name: 'Deployments',
    link: { path: '/workloads/deployments/(add)?', exact: true },
    component: DeploymentsListPage,
  },
  {
    name: 'Deployment Details',
    link: { path: '/workloads/clusters/:clusterId/deployments/:id/:tab?', exact: true },
    component: DeploymentOverviewPage,
  },
  {
    name: 'Services',
    link: { path: '/workloads/services/(add)?', exact: true },
    component: ServicesListPage,
  },
  {
    name: 'Service Details',
    link: { path: '/workloads/clusters/:clusterId/services/:id/:tab?', exact: true },
    component: ServiceOverviewPage,
  },
  {
    name: 'Stateful Sets',
    link: { path: '/workloads/stateful-sets/(add)?', exact: true },
    component: StatefulSetsListPage,
  },
  {
    name: 'Stateful Set Details',
    link: { path: '/workloads/clusters/:clusterId/stateful-sets/:id/:tab', exact: true },
    component: StatefulSetOverviewPage,
  },
  {
    name: 'Stateful Set Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/stateful-sets/:id/containers/:containerName',
      exact: true,
    },
    component: StatefulSetsContainerDetailsPage,
  },
  {
    name: 'Replica Sets',
    link: { path: '/workloads/replica-sets/(add)?', exact: true },
    component: ReplicaSetsListPage,
  },
  {
    name: 'Replica Set Details',
    link: { path: '/workloads/clusters/:clusterId/replica-sets/:id/:tab', exact: true },
    component: ReplicaSetOverviewPage,
  },
  {
    name: 'Replica Set Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/replica-sets/:id/containers/:containerName',
      exact: true,
    },
    component: ReplicaSetsContainerDetailsPage,
  },
  {
    name: 'Daemon Sets',
    link: { path: '/workloads/daemon-sets/(add)?', exact: true },
    component: DaemonSetsListPage,
  },
  {
    name: 'Daemon Set Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/daemon-sets/:id/containers/:containerName',
      exact: true,
    },
    component: DaemonSetContainerDetailsPage,
  },
  {
    name: 'Daemon Set Details',
    link: {
      path: '/workloads/clusters/:clusterId/daemon-sets/:id/:tab?',
      exact: true,
    },
    component: DaemonSetOverviewPage,
  },
  {
    name: 'Cronjobs',
    link: { path: '/workloads/cron-jobs/(add)?', exact: true },
    component: CronjobsListPage,
  },
  {
    name: 'Jobs',
    link: { path: '/workloads/jobs/(add)?', exact: true },
    component: CronjobsListPage,
  },
  {
    name: 'Cronjob Details',
    link: { path: '/workloads/clusters/:clusterId/cron-jobs/:id/:tab?', exact: true },
    component: CronjobOverviewPage,
  },
  {
    name: 'CronJob Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/cron-jobs/:id/containers/:containerName',
      exact: true,
    },
    component: CronjobContainerDetailsPage,
  },
  {
    name: 'Job Details',
    link: { path: '/workloads/clusters/:clusterId/jobs/:id/:tab?', exact: true },
    component: JobOverviewPage,
  },
  {
    name: 'Job Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/jobs/:id/containers/:containerName',
      exact: true,
    },
    component: JobContainerDetailsPage,
  },
  {
    name: 'Namespaces',
    link: { path: '/workloads/namespaces/(add)?', exact: true },
    component: NamespacesListPage,
  },
  {
    name: 'CSI Drivers',
    link: { path: '/storage/csi-drivers', exact: true },
    component: CSIDriversListPage,
  },
  {
    name: 'Persistent Volumes',
    link: { path: '/storage/persistent-volumes', exact: true },
    component: PersistentVolumesListPage,
  },
  {
    name: 'Persistent Volume Claims',
    link: { path: '/storage/persistent-volume-claims', exact: true },
    component: PersistentVolumeClaimsListPage,
  },
  {
    name: 'Storage Classes',
    link: { path: '/storage/storage-classes/(add)?', exact: true },
    component: StorageClassListPage,
  },
  {
    name: 'Storage Providers',
    link: { path: '/storage/storage-providers', exact: true },
    component: StorageProvidersListPage,
  },
  // TODO why not put storage details as a page inside of cluster details?
  {
    name: 'Storage Class Details',
    link: { path: '/storage/clusters/:clusterId/storage-classes/:id/:tab?', exact: true },
    component: StorageDetailsPage,
  },
  {
    name: 'API Access API Services',
    link: { path: '/api-access/api-services', exact: true },
    component: ApiServicesPage,
  },
  {
    name: 'API Access Kubeconfig',
    link: { path: '/api-access/kubeconfig', exact: true },
    component: KubeConfigListPage,
  },
  {
    name: 'API Access Terraform',
    link: { path: '/api-access/terraform', exact: true },
    component: TerraformListPage,
  },
  {
    name: 'Guided Onboarding',
    link: { path: '/onboarding', exact: true },
    component: OnboardingPage,
  },
  {
    name: 'RBAC Roles',
    requiredRoles: 'admin',
    link: { path: '/rbac/roles/(add||edit)?/:id?/(cluster)?/:clusterId?', exact: true },
    component: RolesListPage,
  },
  {
    name: 'RBAC Role Bindings',
    requiredRoles: 'admin',
    link: { path: '/rbac/role-bindings/(add||edit)?/:id?/(cluster)?/:clusterId?', exact: true },
    component: RoleBindingsListPage,
  },
  {
    name: 'RBAC Cluster Roles',
    requiredRoles: 'admin',
    link: { path: '/rbac/cluster-roles/(add||edit)?/:id?/(cluster)?/:clusterId?', exact: true },
    component: ClusterRolesListPage,
  },
  {
    name: 'RBAC Cluster Role Bindings',
    requiredRoles: 'admin',
    link: {
      path: '/rbac/cluster-role-bindings/(add||edit)?/:id?/(cluster)?/:clusterId?',
      exact: true,
    },
    component: ClusterRoleBindingsListPage,
  },
  {
    name: 'Monitoring Alarms',
    link: { path: '/monitoring/alarms', exact: true },
    component: AlarmChartListPage,
  },
  {
    name: 'Monitoring Rules',
    link: { path: '/monitoring/rules', exact: true },
    component: RulesListPage,
  },
  {
    name: 'Help',
    link: { path: '/help/:tab?', exact: true },
    component: HelpPage,
  },
])

// These nav items are in active development but not shown in production.
const navItems = [
  {
    name: 'Dashboard',
    link: { path: '/dashboard/overview', definition: '/dashboard/:tab?/(edit)?' },
    icon: 'tachometer',
    nestedLinks: [
      { name: 'Overview', link: { path: '/dashboard/overview' } },
      { name: 'Alarms', link: { path: '/dashboard/alarms' } },
    ],
  },
  {
    name: 'Workloads',
    link: { path: '/workloads/pods', definition: '/workloads/(clusters)?/:id?/:page' },
    icon: 'cubes',
    nestedLinks: [
      {
        name: 'Pods',
        link: { path: '/workloads/pods', definition: '/workloads/(clusters)?/:id?/pods' },
      },
      {
        name: 'Deployments',
        link: {
          path: '/workloads/deployments',
          definition: '/workloads/(clusters)?/:id?/deployments',
        },
      },
      {
        name: 'Services',
        link: { path: '/workloads/services', definition: '/workloads/(clusters)?/:id?/services' },
      },
      {
        name: 'Stateful Sets',
        link: {
          path: '/workloads/stateful-sets',
          definition: '/workloads/(clusters)?/:id?/stateful-sets',
        },
      },
      {
        name: 'Replica Sets',
        link: {
          path: '/workloads/replica-sets',
          definition: '/workloads/(clusters)?/:id?/replica-sets',
        },
      },
      {
        name: 'Daemon Sets',
        link: {
          path: '/workloads/daemon-sets',
          definition: '/workloads/(clusters)?/:id?/daemon-sets',
        },
      },
      {
        name: 'Cron Jobs & Jobs',
        link: {
          path: '/workloads/cron-jobs',
          definition: '/workloads/(clusters)?/:id?/cron-jobs',
        },
      },
      {
        name: 'Namespaces',
        link: {
          path: '/workloads/namespaces',
          definition: '/workloads/(clusters)?/:id?/namespaces',
        },
      },
    ],
  },
  {
    name: 'Storage',
    icon: 'hdd',
    link: { path: '/storage/storage-classes', definition: '/storage/(clusters)?/:id?/:page' },
    nestedLinks: [
      {
        name: 'CSI Drivers',
        link: { path: '/storage/csi-drivers', definition: '/storage/(clusters)?/:id?/csi-drivers' },
      },
      {
        name: 'Persistent Volumes',
        link: {
          path: '/storage/persistent-volumes',
          definition: '/storage/(clusters)?/:id?/persistent-volumes',
        },
      },
      {
        name: 'Persistent Volume Claims',
        link: {
          path: '/storage/persistent-volume-claims',
          definition: '/storage/(clusters)?/:id?/persistent-volume-claims',
        },
      },
      {
        name: 'Storage Classes',
        link: {
          path: '/storage/storage-classes',
          definition: '/storage/(clusters)?/:id?/storage-classes',
        },
      },
      {
        name: 'Storage Providers',
        link: {
          path: '/storage/storage-providers',
          definition: '/storage/(clusters)?/:id?/storage-providers',
        },
      },
    ],
  },
  {
    name: 'Apps',
    link: { path: '/apps/app-catalog', definition: '/apps/:page' },
    icon: 'th',
    requiredFeatures: isDecco,
    nestedLinks: [
      { name: 'App Catalog', link: { path: '/apps/app-catalog' } },
      { name: 'Deployed Apps', link: { path: '/apps/deployed-apps' } },
      {
        name: ' Helm Repositories',
        link: { path: '/apps/repositories', requiredRoles: ['admin'] },
      },
    ],
  },
  {
    name: 'Monitoring',
    icon: 'analytics',
    link: { path: '/monitoring/alarms', definition: '/monitoring/:page' },
    nestedLinks: [
      { name: 'Alarms', link: { path: '/monitoring/alarms' } },
      { name: 'Rules', link: { path: '/monitoring/rules' } },
    ],
  },
  {
    name: 'RBAC',
    icon: 'user-shield',
    requiredRoles: 'admin',
    link: {
      path: '/rbac/roles',
      definition: '/rbac/(roles|role-bindings|cluster-roles|cluster-role-bindings)',
    },
    nestedLinks: [
      { name: 'Roles', link: { path: '/rbac/roles' } },
      { name: 'Role Bindings', link: { path: '/rbac/role-bindings' } },
      { name: 'Cluster Roles', link: { path: '/rbac/cluster-roles' } },
      { name: 'Cluster Role Bindings', link: { path: '/rbac/cluster-role-bindings' } },
    ],
  },
  {
    name: 'API Access',
    icon: 'key',
    link: { path: '/api-access/api-services', definition: '/api-access/:tab' },
    nestedLinks: [
      { name: 'API Services', link: { path: '/api-access/api-services' } },
      { name: 'Kubeconfig', link: { path: '/api-access/kubeconfig' } },
      { name: 'Terraform', link: { path: '/api-access/terraform' } },
    ],
  },
  {
    name: 'Help',
    icon: 'question-circle',
    link: { path: '/help/support', definition: '/help/:tab' },
    isBottomLink: true,
    nestedLinks: [],
  },
]

kubernetesPlugin.registerNavItems(navItems)

export default kubernetesPlugin
