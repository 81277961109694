import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import jsYaml from 'js-yaml'
import { ensureArray, pathStr } from 'utils/fp'
import DeleteAction from 'core/actions/DeleteAction'
import { someAsync } from 'utils/async'
import { flatten } from 'ramda'

const { qbert } = ApiClient.getInstance()

const statefulSetActions = ActionsSet.make<DataKeys.StatefulSets>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey[DataKeys.StatefulSets],
  cacheKey: DataKeys.StatefulSets,
})

export default statefulSetActions

export const listStatefulSets = statefulSetActions.add(
  new ListAction<DataKeys.StatefulSets, { clusterId: string; namespace?: string }>(
    async (params) => {
      const { clusterId, namespace } = params
      Bugsnag.leaveBreadcrumb('Attempting to get StatefulSets', params)
      return qbert.getStatefulSets(clusterId, namespace)
    },
  ).addDependency(DataKeys.Pods),
)

export const createStatefulSet = statefulSetActions.add(
  new CreateAction<DataKeys.StatefulSets, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create a new Stateful Set', {
        clusterId,
        namespace,
        yaml,
      })
      const body = jsYaml.load(yaml)

      const created = await qbert.createStatefulSet(clusterId, namespace, body)
      trackEvent('Create Stateful Set', {
        id: pathStr('metadata.uid', created),
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateStatefulSet = statefulSetActions.add(
  new UpdateAction<
    DataKeys.StatefulSets,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
    }
  >(async (params) => {
    const { clusterId, namespace, name, body } = params
    const result = await qbert.updateStatefulSet(clusterId, namespace, name, body)
    trackEvent('Update StatefulSet', params)
    return result
  }),
)

export const deleteStatefulSet = statefulSetActions.add(
  new DeleteAction<
    DataKeys.StatefulSets,
    {
      clusterId: string
      namespace: string
      name: string
    }
  >(async (params) => {
    const { clusterId, namespace, name } = params
    Bugsnag.leaveBreadcrumb('Attempting to delete a Stateful Set', params)
    await qbert.deleteStatefulSet(clusterId, namespace, name)
    trackEvent('Delete StatefulSet', params)
  }),
)
