import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import UserMultiSelect from 'k8s/components/common/UserMultiSelect'
import GroupMultiSelect from 'k8s/components/common/GroupMultiSelect'
import RolePicklist from './RolePicklist'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createRoleBinding } from './new-actions'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

const defaultParams = {
  clusterId: '', // Initialize here to prevent desync with formContext
  namespace: '',
  role: null,
  users: [],
  groups: [],
}

export default function AddRoleBindingForm() {
  const classes = useStyles({})
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const { update, updating, error: errorCreatingRoleBinding, reset } = useUpdateAction(
    createRoleBinding,
  )

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.rbac.roleBindings.path({ plugin: currentPluginId }))
  }

  return (
    <>
      <DocumentMeta title="Add Role Binding" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.rbac.addRoleBindings}
        title="Add Role Binding"
        onSubmit={submitForm}
        onClose={handleClose}
        submitting={updating}
        error={errorCreatingRoleBinding}
        submitTitle="Add Role Binding"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <TextField id="name" label="Name" required />
            <PicklistField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Cluster"
              onChange={getParamsUpdater('clusterId')}
              value={params.clusterId}
              onlyHealthyClusters
              setInitialCluster
              compact={false}
              showAll={false}
              required
            />
            <PicklistField
              DropdownComponent={NamespacePicklist}
              disabled={!params.clusterId}
              id="namespace"
              label="Namespace"
              clusterId={params.clusterId}
              onChange={getParamsUpdater('namespace')}
              value={params.namespace}
              setInitialNamespace
              compact={false}
              showAll={false}
              required
            />
            <PicklistField
              DropdownComponent={RolePicklist}
              disabled={!params.clusterId}
              id="role"
              label="Role"
              clusterId={params.clusterId}
              onChange={getParamsUpdater('role')}
              value={params.role}
              namespace={params.namespace}
              showAllRoleTypes
              required
            />
          </FormFieldSection>
          <FormFieldSection title="Assign Users to this Binding" step={2}>
            <UserMultiSelect
              id="users"
              tooltip="Select users to assign this role"
              onChange={getParamsUpdater('users')}
              value={params.users}
            />
          </FormFieldSection>
          <FormFieldSection title="Assign Groups to this Binding" step={3}>
            <GroupMultiSelect
              id="groups"
              tooltip="Select groups to assign this role"
              onChange={getParamsUpdater('groups')}
              value={params.groups}
            />
          </FormFieldSection>
        </>
      </ModalForm>
    </>
  )
}
