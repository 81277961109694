import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import {
  calicoDefaults,
  CalicoDetectionTypes,
} from 'app/plugins/infrastructure/components/clusters/form-components/CalicoNetworkFields'
import { NetworkStackTypes } from 'app/plugins/infrastructure/components/clusters/constants'

const cniIngressRulesDefaults = [
  {
    description: 'bgp (calico)',
    protocol: 'tcp',
    fromPort: 179,
    toPort: 179,
  },
  {
    description: 'IP-in-IP (calico)',
    protocol: '4',
    fromPort: -1,
    toPort: 65535,
  },
  {
    description: 'calico typha (pf9)',
    protocol: 'tcp',
    fromPort: 5473,
    toPort: 5473,
  },
]

export enum NetworkBackendTypes {
  Flannel = 'flannel',
  Calico = 'calico',
  Aws = 'aws',
}

export enum CalicoCapiCnikeys {
  IPv4DetectionMethod = 'IPv4DetectionMethod',
  IPIPMode = 'IPIPMode',
  natOutgoing = 'natOutgoing',
  IPv4BlkSize = 'IPv4BlkSize',
  MTUSize = 'MTUSize',
}

enum CalicoIpIpModes {
  Always = 'Always',
  CrossSubnet = 'CrossSubnet',
  Never = 'Never',
}

export interface CalicoCapiCni {
  [CalicoCapiCnikeys.IPv4DetectionMethod]: CalicoDetectionTypes
  [CalicoCapiCnikeys.IPIPMode]: CalicoIpIpModes
  [CalicoCapiCnikeys.natOutgoing]: boolean
  [CalicoCapiCnikeys.IPv4BlkSize]: number
  [CalicoCapiCnikeys.MTUSize]: number
}

const networkBackendOptions = [
  // { label: 'Flannel', value: NetworkBackendTypes.Flannel }, // Need to check the correct payload for flannel, the api is failing with an empty object
  { label: 'Calico', value: NetworkBackendTypes.Calico },
  { label: 'AWS', value: NetworkBackendTypes.Aws },
]

export const handleNetworkBackendChange = (option, stack) => {
  switch (option) {
    case NetworkBackendTypes.Calico:
      return {
        networkPlugin: option,
        calico: calicoDefaults,
        cniIngressRules: cniIngressRulesDefaults,
        aws: null,
        flannel: null,
      }

    case NetworkBackendTypes.Aws:
      return {
        networkPlugin: option,
        aws: {
          externalSNAT: true,
        },
        calico: null,
        cniIngressRules: null,
        flannel: null,
      }

    default:
      return {
        networkPlugin: option,
        flannel: {},
        calico: null,
        niIngressRules: null,
        aws: null,
      }
  }
}

const CapiAwsNetworkBackendField = ({
  options = networkBackendOptions,
  wizardContext,
  setWizardContext,
}) => (
  <PicklistField
    DropdownComponent={AsyncDropdown}
    id="networkPlugin"
    label="Network backend"
    onChange={(value) =>
      setWizardContext(handleNetworkBackendChange(value, wizardContext.networkStack))
    }
    items={options}
    value={wizardContext.networkPlugin}
    tooltip={
      wizardContext.networkPlugin === NetworkBackendTypes.Aws
        ? 'AWS enables and configures the AWS VPC-CNI as the  CNI for the cluster.'
        : ''
    }
    disabled={wizardContext.networkStack !== NetworkStackTypes.IPv4}
    required
  />
)

export default CapiAwsNetworkBackendField
