import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'

const { qbert } = ApiClient.getInstance()

export const configMapActions = ActionsSet.make<DataKeys.ConfigMaps>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ConfigMaps],
  cacheKey: DataKeys.ConfigMaps,
})

export const listConfigMaps = configMapActions.add(
  new ListAction<DataKeys.ConfigMaps, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Config Maps', params)
    return qbert.getConfigMaps(params.clusterId)
  }),
)
