import React, { useCallback, useEffect, useMemo } from 'react'
import yaml from 'js-yaml'
import TextField from 'core/components/validatedForm/TextField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { ClusterCloudPlatforms, codeMirrorOptions } from 'app/constants'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import useReactRouter from 'use-react-router'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Theme from 'core/themes/model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { routes } from 'core/utils/routes'
import { getSelectedCluster } from 'k8s/components/common/cluster-resource-wizard-meta'
import Progress from 'core/components/progress/Progress'
import { RootState } from 'app/store'
import StorageClassConfigFields from './storage-class-config-fields'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import { listStorageClasses, createStorageClass } from '../new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { storageClassSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { requiredValidator, yamlValidator } from 'core/utils/fieldValidators'

const initialContext = {
  name: '',
  cloudProviderType: '',
  cloudProviderIconType: '',
  isDefault: false,
  csiDriver: '',
  provisioner: '',
  storageClassName: '',
  storageType: 'gp2',
  fsType: 'ext4',
  pdType: 'pd-standard',
  azureDiskType: '',
  kind: 'managed',
  replicationType: 'none',
  skuName: '',
  location: '',
  storageAccount: '',
  resourceGroup: '',
}

const codeMirrorValidations = [requiredValidator, yamlValidator]

export default function AddStorageClassModal() {
  const { history } = useReactRouter()
  const clusters = useSelector<RootState>(allClustersSelector)
  const { update: create, updating: creatingStorageClass, error, reset } = useUpdateAction(
    createStorageClass,
  )

  const handleSubmit = useCallback(
    async (data) => {
      const { success } = await create(data)
      if (success) handleClose()
    },
    [create],
  )

  if (creatingStorageClass) {
    const message = creatingStorageClass ? 'Creating Storage Class...' : 'Loading Clusters...'
    return <Progress loading message={message} />
  }

  const handleClose = () => {
    reset()
    history.push(routes.storage.storageClasses.list.path())
  }

  return (
    <ModalForm
      route={routes.storage.storageClasses.add}
      title="Add Storage Class"
      onSubmit={handleSubmit}
      error={error}
      submitting={creatingStorageClass}
      onClose={handleClose}
      submitTitle="Add Storage Class"
      initialValues={initialContext}
    >
      {({ setFieldValue, values, setValues }) => {
        return (
          <AddStorageClass
            clusters={clusters}
            values={values}
            setFieldValue={setFieldValue}
            setValues={setValues}
          />
        )
      }}
    </ModalForm>
  )
}

export const AddStorageClass = ({ clusters, values, setFieldValue, setValues }) => {
  const classes = useStyles({})
  const { storageClassYaml, ...contextWithoutYaml } = values

  const { loading } = useListAction(listStorageClasses, {
    params: { clusterId: values.clusterId },
    requiredParams: ['clusterId'],
  })
  const storageClasses = useSelectorWithParams(storageClassSelector, {
    clusterId: values.clusterId,
  })

  const defaultExists = useMemo(() => {
    return !!storageClasses.find((sc) => sc.clusterId === values.clusterId && sc.isDefault)
  }, [storageClasses, values.clusterId])

  const selectedCluster = getSelectedCluster(clusters, values.clusterId)

  useEffect(() => {
    if (selectedCluster) {
      setFieldValue('cloudProviderType')(selectedCluster.cloudProviderType)
    }
  }, [selectedCluster])

  useEffect(() => {
    const newStorageClassYaml = getInitialStorageClassYaml(contextWithoutYaml)
    if (newStorageClassYaml !== storageClassYaml) {
      setFieldValue('storageClassYaml')(newStorageClassYaml)
    }
  }, Object.values(contextWithoutYaml))

  return (
    <>
      <FormFieldSection title="Basic Settings" step={1}>
        <PicklistField
          DropdownComponent={ClusterPicklist}
          id="clusterId"
          label="Select Cluster"
          tooltip="The cluster to deploy this storage class on."
          onChange={setFieldValue('clusterId')}
          value={values.clusterId}
          onlyHealthyClusters
          setInitialCluster
          compact={false}
          required
        />
        <TextField
          id="storageClassName"
          label="Storage Class Name"
          onChange={setFieldValue('storageClassName')}
          value={values.storageClassName}
          required
        />
      </FormFieldSection>
      <FormFieldSection title="Choose a Provisioner">
        <StorageClassConfigFields
          clusterVersion={selectedCluster?.version}
          defaultExists={defaultExists}
          loading={loading}
          wizardContext={values}
          setWizardContext={setValues}
        />
      </FormFieldSection>
      <FormFieldSection title="Resource YAML" step={2}>
        <CodeMirror
          variant="light"
          className={classes.codeMirror}
          id="storageClassYaml"
          options={codeMirrorOptions}
          onChange={setFieldValue('storageClassYaml')}
          value={storageClassYaml}
          validations={codeMirrorValidations}
          showSearchBar
        />
      </FormFieldSection>
    </>
  )
}

interface IStorageClassYaml {
  apiVersion: string
  kind: string
  metadata: {
    name: any
    annotations: {
      [key: string]: any
    }
    labels: {
      [key: string]: any
    }
  }
  provisioner?: string
  parameters?: {
    [key: string]: any
  }
}

const getInitialStorageClassYaml = (values) => {
  const storageClassJson: IStorageClassYaml = {
    apiVersion: 'storage.k8s.io/v1',
    kind: 'StorageClass',
    metadata: {
      name: values.storageClassName,
      annotations: {
        'storageclass.kubernetes.io/is-default-class': values.isDefault.toString(),
      },
      labels: {
        'kubernetes.io/cluster-service': 'true',
      },
    },
    provisioner: `kubernetes.io/${values.provisioner}`,
  }
  if (values.cloudProviderType === CloudProviders.Aws) {
    storageClassJson.parameters = {
      type: values.storageType,
      fsType: values.fsType,
    }
  }
  if (values.cloudProviderType === CloudProviders.Azure) {
    if (values.azureDiskType === 'azure-disk managed standard') {
      storageClassJson.parameters = {
        storageAccount: values.storageAccount,
        kind: values.kind,
        resourceGroup: values.resourceGroup,
      }
    } else {
      storageClassJson.parameters = {
        skuName: values.skuName,
        location: values.location,
        storageAccount: values.storageAccount,
      }
    }
  }
  if (values.cloudProviderType === ClusterCloudPlatforms.GKE) {
    storageClassJson.parameters = {
      type: values.azureDiskType,
      replicationType: values.replicationType,
    }
  }

  return yaml.dump(storageClassJson)
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  blueIcon: {
    color: theme.palette.primary.main,
  },
  codeMirror: {
    width: '100% !important',
    marginTop: '0 !important',
  },
  yamlContainer: {
    position: 'relative',

    '& .form-field-card-requirementsTitle': {
      minHeight: '40px',
      alignItems: 'center',
      marginBottom: '8px',
    },
    '& .CodeMirror-actionsBar': {
      position: 'absolute',
      top: 8,
      right: 16,
    },
  },
  search: {
    maxWidth: 240,
  },
  editButton: {
    margin: '0px 8px',
  },
}))
