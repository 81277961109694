import React from 'react'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import useDataLoader from 'core/hooks/useDataLoader'
import { loadCloudProviderDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface CloudProviderRegionPicklistProps extends Omit<DropdownProps<string>, 'items'> {
  cloudProviderId?: string
  type?: CloudProviders
  id?: string
}

export default function CloudProviderRegionPicklist({
  cloudProviderId,
  type,
  ...rest
}: CloudProviderRegionPicklistProps) {
  const [details, loading] = useDataLoader(loadCloudProviderDetails, {
    cloudProviderId: cloudProviderId,
  })

  const displayField = cloudProviderId && type === CloudProviders.Aws ? 'RegionName' : 'DisplayName'
  const options = details.map((detail) => ({
    label: detail[displayField],
    value: detail.RegionName,
  }))

  return <Picklist {...rest} loading={loading} items={options} />
}
