import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { renderResourceLabels } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { castBoolToStr } from 'utils/misc'
import { persistentVolumeClaimsListTableColumns } from 'k8s/components/common/entity/constants'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { listPersistentVolumes } from 'k8s/components/storage/persistent-volume/new-actions'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import useListAction from 'core/hooks/useListAction'
import Card from 'core/elements/card'
import Grid from 'core/elements/grid'
import Progress from 'core/components/progress/Progress'
import InfoCard from 'k8s/components/common/entity/info-card'
import LabelsAndAnnotationsTabs from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { pvDetailOptions } from '../persistent-volume/PersistentVolumesListPage'

const renderParameters = (value) => {
  if (!value) {
    return <Text variant="caption1">No Parameters</Text>
  }
  return renderResourceLabels({ separator: '=', variant: 'default' })(value)
}

const renderFieldOrEmpty = ({ fn }) => (value) => (value === undefined ? '' : fn(value))
const renderBool = renderFieldOrEmpty({ fn: castBoolToStr('True', 'False') })

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  { id: 'provisioner', title: 'Provisioner', required: true },
  {
    id: 'isDefault',
    title: 'Is Default',
    required: true,
    render: renderBool,
  },
  { id: 'reclaimPolicy', title: 'Reclaim Policy', required: true },
  { id: 'volumeBindingMode', title: 'Volume Binding Mode', required: true },
  {
    id: 'allowVolumeExpansion',
    title: 'Allow Volume Expansion',
    required: true,
    render: renderBool,
  },
  {
    id: 'parameters',
    title: 'Parameters',
    required: true,
    render: renderParameters,
  },
  {
    id: 'age',
    title: 'Age',
    required: true,
  },
]

export default function StorageClassOverview({ storageClass, loading }) {
  const classes = useStyles()
  const { loading: loadingPersistentVolumes } = useListAction(listPersistentVolumes, {
    params: {
      clusterId: storageClass?.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const { loading: loadingPersistentVolumeClaims } = useListAction(listPersistentVolumeClaims, {
    params: {
      clusterId: storageClass?.clusterId,
    },
    requiredParams: ['clusterId'],
  })

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, storageClass)
  }, [storageClass])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={
              <LabelsAndAnnotationsTabs
                labels={storageClass?.labels}
                annotations={storageClass?.annotations}
              />
            }
          />
        </div>
        <div className={classes.column}>
          <Card title="Persistent Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={storageClass?.persistentVolumes}
              columns={pvDetailOptions.columns}
              loading={loadingPersistentVolumes || loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Persistent Volume Claims" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={storageClass?.persistentVolumeClaims}
              columns={persistentVolumeClaimsListTableColumns}
              loading={loadingPersistentVolumeClaims || loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
}))
