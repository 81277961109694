import { clientActions } from 'core/client/clientReducers'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import EntityContainerDetailsPage from '../common/entity/entity-container-details-page'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { statefulSetSelector } from './selectors'
import { listStatefulSets } from './actions'

export default function StatefulSetsContainerDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, containerName } = match.params
  const { loading } = useListAction(listStatefulSets, {
    params: { clusterId },
  })
  const statefulSets = useSelectorWithParams(statefulSetSelector, { clusterId })
  const statefulSet = useMemo(() => statefulSets.find((statefulSet) => statefulSet.id === id), [
    id,
    statefulSets,
  ])
  const spec = statefulSet?.podTemplate?.spec
  const container = useMemo(
    () =>
      spec?.initContainers?.find((c) => c.name === containerName) ||
      spec?.containers?.find((c) => c.name === containerName) ||
      {},
    [spec],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: statefulSet?.clusterName || clusterId,
        id: statefulSet?.name || id,
        containerName,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [statefulSet?.clusterName, statefulSet?.name, id, clusterId, containerName])

  return <EntityContainerDetailsPage entity={statefulSet} container={container} loading={loading} />
}
