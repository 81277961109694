import { GenericObject } from 'api-client/qbert.model'
import { allKey } from 'app/constants'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { complement, isNil, pipe, propEq } from 'ramda'
import { createSelector } from '@reduxjs/toolkit'
import { arrayIfEmpty, emptyArr, emptyObj, filterIf } from 'utils/fp'
import { getK8sDashboardLinkFromVersion } from '../../../infrastructure/components/clusters/action-helpers'
import { getResourcePods } from '../pods/helpers'
import { podsByClusterIdAndNamespaceSelector } from '../pods/selectors'
import { getServicesForResource } from '../services/helpers'
import { servicesByClusterIdAndNamespaceSelector } from '../services/selectors'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { durationBetweenDates } from 'utils/misc'
import { IDeploymentSelector } from 'k8s/components/deployments/model'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { qbertEndpointSelector } from 'app/plugins/infrastructure/components/common/selectors'

export const deploymentsSelector = createSharedSelector(
  getDataSelector<DataKeys.Deployments>(
    DataKeys.Deployments,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  podsByClusterIdAndNamespaceSelector,
  servicesByClusterIdAndNamespaceSelector,
  allClustersSelector,
  qbertEndpointSelector,
  (
    rawDeployments,
    podsByClusterIdAndNamespace,
    servicesByClusterIdAndNamespaceSelector,
    allClusters,
    qbertEndpoint,
  ): IDeploymentSelector[] => {
    const clusters = {}
    allClusters.forEach((c) => {
      clusters[c.uuid] = c
    })
    return arrayIfEmpty(
      rawDeployments
        .map((rawDeployment) => {
          const { clusterId } = rawDeployment
          const cluster = clusters[clusterId]
          if (!cluster) {
            // If no cluster is found, this item is invalid because the parent cluster has been deleted
            return null
          }
          const selectors =
            rawDeployment?.spec?.selector?.matchLabels || (emptyObj as GenericObject)
          const name = rawDeployment?.metadata?.name
          const namespace = rawDeployment?.metadata?.namespace

          const k8sDashboardUrl = getK8sDashboardLinkFromVersion(qbertEndpoint, cluster)
          const dashboardUrl = `${k8sDashboardUrl}#/deployment/${namespace}/${name}?namespace=${namespace}`
          const associatedPods = podsByClusterIdAndNamespace?.[clusterId]?.[namespace] || emptyArr
          const deploymentPods = getResourcePods(selectors, associatedPods)
          const runningPods = deploymentPods.reduce((acc, pod) => {
            const num = ['Running', 'Succeeded'].includes(pod?.status?.phase) ? 1 : 0
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return (acc = acc + num)
          }, 0)
          const associatedServices =
            servicesByClusterIdAndNamespaceSelector?.[clusterId]?.[namespace] || emptyArr
          const deploymentServices = getServicesForResource(rawDeployment, associatedServices)
          const creationTimestamp = rawDeployment?.metadata?.creationTimestamp
          return {
            ...rawDeployment,
            dashboardUrl,
            id: rawDeployment?.metadata?.uid,
            name,
            created: rawDeployment?.metadata?.creationTimestamp,
            labels: rawDeployment?.metadata?.labels,
            annotations: rawDeployment?.metadata?.annotations,
            selectors,
            pods: deploymentPods.length,
            deploymentPods,
            runningPods,
            deploymentServices,
            namespace,
            clusterName: cluster?.name,
            kind: 'Deployment',
            strategyType: rawDeployment?.spec?.strategy?.type,
            creationTimestamp,
            age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          }
        })
        .filter(complement(isNil)),
    )
  },
)

export const makeDeploymentsSelector = (
  defaultParams = {} as SortConfig & { namespace?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(deploymentsSelector, selectParams, (deployments, params) => {
    const { namespace, orderBy, orderDirection } = params
    return pipe<
      IDeploymentSelector[],
      IDeploymentSelector[],
      IDeploymentSelector[],
      IDeploymentSelector[]
    >(
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(deployments)
  })
}
