import { clientActions } from 'core/client/clientReducers'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import EntityContainerDetailsPage from '../common/entity/entity-container-details-page'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { replicaSetSelector } from './selectors'
import { listReplicaSets } from './actions'

export default function ReplicaSetsContainerDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, containerName } = match.params
  const { loading } = useListAction(listReplicaSets, {
    params: { clusterId },
  })
  const replicaSets = useSelectorWithParams(replicaSetSelector, { clusterId })
  const replicaSet = useMemo(() => replicaSets.find((replicaSet) => replicaSet.id === id), [
    id,
    replicaSets,
  ])
  const spec = replicaSet?.podTemplate?.spec
  const container = useMemo(
    () =>
      spec?.initContainers?.find((c) => c.name === containerName) ||
      spec?.containers?.find((c) => c.name === containerName) ||
      {},
    [spec],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: replicaSet?.clusterName || clusterId,
        id: replicaSet?.name || id,
        containerName,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [replicaSet?.clusterName, replicaSet?.name, id, clusterId, containerName])

  return <EntityContainerDetailsPage entity={replicaSet} container={container} loading={loading} />
}
