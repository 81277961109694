import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import Grid from 'core/elements/grid'
import { routes } from 'core/utils/routes'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { IJobSelector } from 'k8s/components/cronjobs/job-model'

const useStyles = makeStyles<Theme>((theme) => ({
  jobs: {
    marginTop: '16px',
  },
}))

const usePrefParams = createUsePrefParamsHook('Events', listTablePrefs)

const columns: GridViewColumn<IJobSelector>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ type, clusterId, id }) => {
        const routeKey = type === 'job' ? 'jobs' : 'cronjobs'
        return routes[routeKey].details.path({ clusterId, id })
      },
    }),
  },
  { key: 'namespace', label: 'Namespace' },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  {
    key: 'age',
    label: 'Age',
  },
]

const ActiveJobsPage = ({ jobs = [], loading, reload }) => {
  const classes = useStyles()

  return (
    <article className={classes.jobs}>
      <Grid
        label="Active Jobs"
        data={jobs}
        loading={loading}
        columns={columns}
        uniqueIdentifier="id"
        onReload={reload}
      />
    </article>
  )
}

export default ActiveJobsPage
