import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'

const { qbert } = ApiClient.getInstance()

export const dataVolumeActions = ActionsSet.make<DataKeys.DataVolumes>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey[DataKeys.DataVolumes],
  cacheKey: DataKeys.DataVolumes,
})

export const listDataVolumes = dataVolumeActions.add(
  new ListAction<DataKeys.DataVolumes, { clusterId: string; namespace: string }>(
    async ({ clusterId, namespace }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load data volumes', {
        clusterId,
        namespace,
      })
      return qbert.getDataVolumes(clusterId, namespace)
    },
  ),
)
