import React, { useMemo } from 'react'
import Picklist from 'core/elements/dropdown/AsyncDropdown'
import useDataLoader from 'core/hooks/useDataLoader'
import { projectAs } from 'utils/fp'
import { propEq, always } from 'ramda'
import { cloudProviderActions } from 'app/plugins/infrastructure/components/cloudProviders/actions'
import { DropdownProps } from 'core/elements/dropdown/Dropdown'

interface CloudProviderPicklistProps extends Omit<DropdownProps<string>, 'items'> {
  type?: string
  id?: string
}

export default function CloudProviderPicklist({
  type,
  showAll = false,
  showNone = false,
  id,
  name = id || 'cloudProviderId',
  label = 'Cloud Provider',
  ...rest
}: CloudProviderPicklistProps) {
  const [cloudProviders, loading] = useDataLoader(cloudProviderActions.list)
  const items = useMemo<{ label: string; value: string }[]>(
    () =>
      projectAs(
        { value: 'uuid', label: 'name' },
        cloudProviders.filter(type ? propEq('type', type) : always(true)),
      ),
    [cloudProviders],
  )

  return (
    <Picklist
      {...rest}
      name={name}
      label={label}
      showAll={showAll}
      showNone={showNone}
      loading={loading}
      items={items}
    />
  )
}
