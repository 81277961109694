import React, { ComponentProps } from 'react'
import { projectAs } from 'utils/fp'
import withFormContext from 'core/components/validatedForm/withFormContext'
import useDataLoader from 'core/hooks/useDataLoader'
import MultiSelect from 'core/components/MultiSelect'
import { mngmGroupActions } from 'account/components/ssoManagement/groups/actions'

export default withFormContext<
  string[],
  Omit<ComponentProps<typeof MultiSelect>, 'label' | 'options' | 'loading'>
>(function GroupMultiSelect(props) {
  const [groups, loadingGroups] = useDataLoader(mngmGroupActions.list, { orderBy: 'name' })
  const groupsList = projectAs({ label: 'name', value: 'name' }, groups)
  return <MultiSelect {...props} label="Groups" options={groupsList} loading={loadingGroups} />
})
