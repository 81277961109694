import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import React from 'react'
import { listStatefulSets, deleteStatefulSet } from './actions'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import { pick } from 'ramda'
import { createResourceLabelsCell } from '../common/entity/labels-and-annotations/helpers'
import { routes } from 'core/utils/routes'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { statefulSetSelector } from 'k8s/components/stateful-sets/selectors'
import AddWorkloadResourcePage from 'k8s/components/common/entity/AddResourcePage'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ModelDataKey = DataKeys.StatefulSets
type SelectorModel = ArrayElement<ReturnType<typeof statefulSetSelector>>
type ActionParams = InferActionParams<typeof listStatefulSets>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  namespace?: string
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
}
const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'StatefulSets',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']
const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id }) => routes.statefulSets.details.path({ clusterId, id }),
    }),
  },
  { key: 'namespace', label: 'Namespace' },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  {
    key: 'age',
    label: 'Age',
  },
  { key: 'replicas.desired', label: 'Desired', tooltip: 'The number of desired replicas' },
  {
    key: 'replicas.total',
    label: 'Total',
    tooltip: 'The number of pods created by the StatefulSet controller',
  },
  {
    key: 'replicas.ready',
    label: 'Ready',
    tooltip: 'The number of pods targeted by this ReplicaSet with a Ready condition',
  },
  {
    key: 'selector',
    label: 'Selectors',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'labels',
    label: 'Labels',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'annotations',
    label: 'Annotations',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
]

export default function StatefulSetsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listStatefulSets, {
    params,
    requiredParams,
  })
  const data = useAppSelector(statefulSetSelector)

  return (
    <>
      <DocumentMeta title="Stateful Sets" />
      <AddWorkloadResourcePage resourceType="statefulSet" addRoute={routes.statefulSets.add} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.StatefulSets}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.statefulSets.add.path()}
        addText="Add Stateful Set"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteStatefulSet}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
