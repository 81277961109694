import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import {
  configMapColumns,
  ingressTableColumns,
  persistentVolumeClaimsListTableColumns,
  serviceTableColumns,
} from '../common/entity/constants'
import { getResourceIngresses } from '../ingresses/helpers'
import { getConfigMapsForResource } from '../config-maps/helpers'
import { listPersistentVolumeClaims } from '../storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from '../storage/persistent-volume-claims/selectors'
import InfoCard from '../common/entity/info-card'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsTabs from '../common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Progress from 'core/components/progress/Progress'
import { renderAge } from '../common/entity/helpers'
import Grid from 'core/elements/grid'
import { renderResourceLabels } from '../common/entity/labels-and-annotations/helpers'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listIngresses } from '../ingresses/new-actions'
import { ingressesSelector } from '../ingresses/selectors'
import { listConfigMaps } from '../config-maps/new-actions'
import { configMapsSelector } from '../config-maps/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  noneText: {
    margin: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  {
    id: 'selectors',
    title: 'Selector',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  { id: 'strategyType', title: 'Strategy', required: true },
  {
    id: 'age',
    title: 'Age',
    required: true,
    render: renderAge,
  },
]

const pvcSelector = makePersistentVolumeClaimSelector()

const DeploymentOverview = ({ deployment, loading }) => {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: deployment?.clusterId,
    }),
    [deployment],
  )
  const { loading: loadingIngresses } = useListAction(listIngresses, {
    params,
    requiredParams: ['clusterId'],
  })
  const allIngresses = useSelectorWithParams(ingressesSelector, params)

  const { loading: loadingConfigMaps } = useListAction(listConfigMaps, {
    params,
    requiredParams: ['clusterId'],
  })
  const allConfigMaps = useSelectorWithParams(configMapsSelector, params)

  const { loading: loadingPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })

  const allPvcs = useSelectorWithParams(pvcSelector, params)

  const ingresses = useMemo(
    () => getResourceIngresses(deployment?.deploymentServices, allIngresses),
    [allIngresses, deployment],
  )

  const configMaps = useMemo(() => getConfigMapsForResource(deployment, allConfigMaps), [
    allConfigMaps,
    deployment,
  ])

  const podNames = useMemo(() => {
    const names = new Set()
    if (!deployment?.deploymentPods) return names
    deployment.deploymentPods.forEach((pod) => {
      names.add(pod.name)
    })
    return names
  }, [deployment])

  const pvcs = useMemo(() => {
    return allPvcs.filter((pvc) => {
      const mounts = pvc.podsMounted || []
      return !!mounts.find((m) => podNames.has(m?.pod))
    })
  }, [allPvcs, deployment])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, deployment)
  }, [deployment])

  return (
    <Progress loading={loading}>
      <article className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={
              <LabelsAndAnnotationsTabs
                labels={deployment?.labels}
                annotations={deployment?.annotations}
              />
            }
          />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Services" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={deployment?.deploymentServices}
              columns={serviceTableColumns}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Ingresses" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={ingresses}
              columns={ingressTableColumns}
              loading={loadingIngresses}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Persistent Volume Claims" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={pvcs}
              columns={persistentVolumeClaimsListTableColumns}
              loading={loadingPvcs}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </article>
    </Progress>
  )
}

export default DeploymentOverview
