import React, { useEffect, useMemo } from 'react'
import { isEmpty } from 'ramda'
import { projectAs, isNilOrEmpty } from 'utils/fp'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import MultiDropdown, { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector()

export interface MultiClusterDropdownProps extends Omit<MultiDropdownProps<string>, 'items'> {
  onlyMasterNodeClusters?: boolean
  onlyPrometheusEnabled?: boolean
  onlyKubevirtClusters?: boolean
  onlyHealthyClusters?: boolean
  onlyEcoEnabledClusters?: boolean
  filterFn?: (clusters: CombinedClusterSelector[]) => CombinedClusterSelector[]
}

export default function MultiClusterDropdown({
  label = 'Cluster:',
  onlyMasterNodeClusters = false,
  onlyPrometheusEnabled = false,
  onlyHealthyClusters = true,
  onlyEcoEnabledClusters = true,
  onlyKubevirtClusters = false,
  loading = false,
  compact = true,
  onChange,
  value,
  filterFn,
  ...rest
}: MultiClusterDropdownProps) {
  const defaultParams = useMemo(
    () => ({
      masterNodeClusters: onlyMasterNodeClusters,
      prometheusClusters: onlyPrometheusEnabled,
      healthyClusters: onlyHealthyClusters,
      ecoEnabledClusters: onlyEcoEnabledClusters,
      kubevirtClusters: onlyKubevirtClusters,
    }),
    [
      onlyMasterNodeClusters,
      onlyPrometheusEnabled,
      onlyHealthyClusters,
      onlyEcoEnabledClusters,
      onlyKubevirtClusters,
    ],
  )

  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)
  const { loading: capiClustersLoading } = useListAction(listCapiClusters)
  const allClusters: CombinedClusterSelector[] = useSelectorWithParams(
    defaultParamsAllClusterSelector,
    defaultParams,
  )
  const isLoading = clustersLoading || importedClustersLoading || capiClustersLoading

  const filteredClusters = useMemo(() => (filterFn ? filterFn(allClusters) : allClusters), [
    allClusters,
    filterFn,
  ])
  const options = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'uuid' }, filteredClusters)]
  }, [filteredClusters])

  useEffect(() => {
    if (!isNilOrEmpty(value) || isEmpty(options) || isLoading) return
    const [firstOption] = options
    onChange([firstOption.value])
  }, [options, isLoading])

  return (
    <MultiDropdown
      compact={compact}
      items={options}
      onChange={onChange}
      label={label}
      loading={loading || isLoading}
      value={value}
      {...rest}
    />
  )
}
