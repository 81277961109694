import React, { useMemo } from 'react'
import {
  listStorageClasses,
  deleteStorageClass,
} from 'k8s/components/storage/storage-classes/new-actions'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import { renderResourceLabels } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import SimpleLink from 'core/components/SimpleLink'
import useListAction from 'core/hooks/useListAction'
import { storageClassSelector } from 'k8s/components/storage/storage-classes/selectors'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import DocumentMeta from 'core/components/DocumentMeta'
import AddStorageClassModal from './add-page/AddStorageClassModal'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import usePluginRouter from 'core/hooks/usePluginRouter'

type ModelDataKey = DataKeys.StorageClasses
type SelectorModel = ArrayElement<ReturnType<typeof storageClassSelector>>
type ActionParams = InferActionParams<typeof listStorageClasses>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}
const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'StorageClasses',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

export default function StorageClassesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { currentPluginId } = usePluginRouter()
  const { message, loading, reload } = useListAction(listStorageClasses, {
    params,
    requiredParams,
  })
  const data = useAppSelector(storageClassSelector)

  const columns: GridViewColumn<SelectorModel>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'Name',
        width: 'medium',
        render: (name, { clusterId, id }) => (
          <SimpleLink
            src={routes.storage.storageClasses.details.path({
              clusterId,
              id,
              plugin: currentPluginId,
            })}
          >
            {name}
          </SimpleLink>
        ),
      } as GridViewColumn<SelectorModel, 'name'>,
      { key: 'clusterName', label: 'Cluster' },
      { key: 'provisioner', label: 'Provisioner' },
      {
        key: 'parameters',
        label: 'Parameters',
        render: renderResourceLabels({ type: 'table', variant: 'default' }),
      },
      {
        key: 'created',
        label: 'Created',
        render: (value: string) => <DateAndTime value={value} />,
      },
    ],
    [currentPluginId],
  )

  return (
    <>
      <DocumentMeta title="Storage Classes" />
      <AddStorageClassModal />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.StorageClasses}
        searchTargets={searchTargets}
        addUrl={routes.storage.storageClasses.add.path({ plugin: currentPluginId })}
        addText="Add Storage Class"
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteStorageClass}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
