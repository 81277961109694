import Bugsnag from '@bugsnag/browser'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import UpdateAction from 'core/actions/UpdateAction'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import jsYaml from 'js-yaml'
import { ensureArray, pathStr } from 'utils/fp'
import DeleteAction from 'core/actions/DeleteAction'
import { someAsync } from 'utils/async'
import { flatten } from 'ramda'

const { qbert } = ApiClient.getInstance()

export const daemonSetActions = ActionsSet.make<DataKeys.DaemonSets>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.DaemonSets,
  cacheKey: DataKeys.DaemonSets,
})

export const listDaemonSets = daemonSetActions.add(
  new ListAction<DataKeys.DaemonSets, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get DaemonSets', params)
    const { clusterId, namespace } = params
    return qbert.getDaemonSets(clusterId, namespace)
  }).addDependency(DataKeys.Pods),
)

export const createDaemonSet = daemonSetActions.add(
  new CreateAction<DataKeys.DaemonSets, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create a new Daemon Set', {
        clusterId,
        namespace,
        yaml,
      })
      const body = jsYaml.load(yaml)

      const created = await qbert.createDaemonSet(clusterId, namespace, body)
      trackEvent('Create Daemon Set', {
        id: pathStr('metadata.uid', created),
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateDaemonSet = daemonSetActions.add(
  new UpdateAction<
    DataKeys.DaemonSets,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
    }
  >(async (params) => {
    const { clusterId, namespace, name, body } = params
    const result = await qbert.updateDaemonSet(clusterId, namespace, name, body)
    trackEvent('Update DaemonSet', params)
    return result
  }),
)

export const deleteDaemonSet = daemonSetActions.add(
  new DeleteAction<
    DataKeys.DaemonSets,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete a DaemonSet', {
      clusterId,
      namespace,
      name,
      id,
    })
    await qbert.deleteDaemonSet(clusterId, namespace, name)
    trackEvent('Delete DaemonSet', { clusterId, namespace, name, id })
  }),
)
