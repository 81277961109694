import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import DataKeys from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import { persistentVolumeActions } from '../persistent-volume/actions'
import { persistentVolumeClaimActions } from '../persistent-volume-claims/actions'
import { csiDriversSelector } from './selectors'
import listFnWithDependencies from 'core/helpers/list-with-dependencies'
import { trackEvent } from 'utils/tracking'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

const csiDriverActions = createCRUDActions(DataKeys.CSIDrivers, {
  listFn: listFnWithDependencies(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get CSI Drivers', params)
      const clusterIds = parseClusterIdsFromParams(params)
      return someAsync(clusterIds.map(qbert.getCSIDrivers)).then(flatten)
    },
    [persistentVolumeActions.list, persistentVolumeClaimActions.list],
  ),
  deleteFn: async ({ id }, currentItems) => {
    const { clusterId, name } = currentItems.find((x) => x.id === id)
    Bugsnag.leaveBreadcrumb('Attempting to delete CSI driver', { id, clusterId, name })
    const result = await qbert.deleteCSIDriver(clusterId, name)
    trackEvent('Delete CSI Driver', { clusterId, name, id })
    return result
  },
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: 'CSIDrivers',
  selector: csiDriversSelector,
})
export default csiDriverActions
