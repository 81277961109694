import { RootState } from 'app/store'
import { Selector } from '@reduxjs/toolkit'
import { complement, isNil, propSatisfies } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { findClusterName } from 'k8s/util/helpers'
import { emptyArr } from 'utils/fp'
import { durationBetweenDates } from 'utils/misc'
import { podsByClusterIdAndNamespaceSelector } from '../pods/selectors'
import { addContainerType } from '../pods/helpers'
import { getVolumeType, volumeTypeDescriptions } from '../storage/helpers'
import { IDaemonSetSelector } from './models'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const daemonSetSelector: Selector<RootState, IDaemonSetSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.DaemonSets>(
    DataKeys.DaemonSets,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  podsByClusterIdAndNamespaceSelector,
  (rawDaemonSets, allClusters, podsByClusterIdAndNamespace) => {
    return rawDaemonSets
      .map((daemonSet) => {
        const clusterId = daemonSet?.clusterId
        const clusterName = findClusterName(allClusters, clusterId)
        const selector = daemonSet?.spec?.selector?.matchLabels
        const creationTimestamp = daemonSet?.metadata?.creationTimestamp
        const associatedPods =
          podsByClusterIdAndNamespace?.[clusterId]?.[daemonSet?.namespace] || emptyArr
        const pods = associatedPods.filter((pod) =>
          pod?.metadata?.ownerReferences?.some((owner) => owner.uid === daemonSet?.id),
        )
        const initContainers = addContainerType(
          daemonSet?.spec?.template?.spec?.initContainers,
          'Init Container',
        )
        const containers = addContainerType(daemonSet?.spec?.template?.spec?.containers)
        const volumes = (daemonSet?.spec?.template?.spec?.volumes || []).map((volume) => {
          const type = getVolumeType(volume)
          return {
            ...volume,
            type,
            description: volumeTypeDescriptions[type],
          }
        })

        return {
          id: daemonSet?.metadata?.uid,
          name: daemonSet?.metadata?.name,
          namespace: daemonSet?.metadata?.namespace,
          clusterId,
          clusterName,
          selector,
          pods,
          initContainers,
          containers,
          volumes,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          creationTimestamp,
          labels: daemonSet?.metadata?.labels,
          annotations: daemonSet?.metadata?.annotations,
          podTemplate: daemonSet?.spec?.template,
          nodeSelector: daemonSet?.spec.template.spec.nodeSelector,
          numberReady: daemonSet?.status?.numberReady || 0,
          numberAvailable: daemonSet?.status?.numberAvailable || 0,
          numberMisscheduled: daemonSet?.status?.numberMisscheduled || 0,
          desiredNumberScheduled: daemonSet?.status?.desiredNumberScheduled || 0,
          currentNumberScheduled: daemonSet?.status?.currentNumberScheduled || 0,
          updatedNumberScheduled: daemonSet?.status?.updatedNumberScheduled || 0,
          tolerations: daemonSet?.spec?.template?.spec?.tolerations || [],
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)
