import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import DataKeys from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import { makePersistentVolumeClaimSelector } from './selectors'
import storageClassActions from '../storage-classes/actions'
import listFnWithDependencies from 'core/helpers/list-with-dependencies'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

export const persistentVolumeClaimActions = createCRUDActions(DataKeys.PersistentVolumeClaims, {
  listFn: listFnWithDependencies(
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to get Persistent Volume Claims', params)
      const clusterIds = parseClusterIdsFromParams(params)
      return someAsync(clusterIds.map(qbert.getPersistentVolumeClaims)).then(flatten)
    },
    [storageClassActions.list],
  ),
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  selectorCreator: makePersistentVolumeClaimSelector,
})
