import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useCallback, useMemo } from 'react'
import {
  configMapColumns,
  taintsAndTolerationsListTableColumns,
} from '../../common/entity/constants'
import useDataLoader from 'core/hooks/useDataLoader'
import { configMapActions } from '../../config-maps/actions'
import { getConfigMapsForResource } from '../../config-maps/helpers'
import InfoCard from '../../common/entity/info-card'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Progress from 'core/components/progress/Progress'
import { isNilOrEmpty } from 'utils/fp'
import Text from 'core/elements/Text'
import { durationBetweenDates } from 'utils/misc'
import Containers from '../../common/entity/containers'
import { renderResourceLabels } from '../../common/entity/labels-and-annotations/helpers'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { getVolumeTypeAndDescription } from 'k8s/components/storage/helpers'
import Grid from 'core/elements/grid'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  noneText: {
    margin: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}))

export const renderLastScheduleTime = (time, entity) => {
  if (!time || isNilOrEmpty(entity)) return ''
  return <Text variant="caption1">{`${durationBetweenDates({ labels: ['d'] })(time)}`}</Text>
}

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  { id: 'spec.schedule', title: 'Schedule' },
  {
    id: 'spec.concurrencyPolicy',
    title: 'Concurrency Policy',
  },
  { id: 'spec.successfulJobsHistoryLimit', title: 'Successful Job History Limit' },
  { id: 'spec.failedJobsHistoryLimit', title: 'Failed Job History Limit' },
  {
    id: 'status.lastScheduleTime',
    title: 'Last Scheduled Time',
    render: renderLastScheduleTime,
  },
]

const podTemplateMetadataFields = [
  {
    id: 'metadata.labels',
    title: 'Labels',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
]

const volumeTableColumns = [
  { key: 'name', label: 'Name' },
  { key: 'type', label: 'Type', formatFn: getVolumeTypeAndDescription },
  { key: 'hostPath.path', label: 'Path' },
  { key: 'hostPath.type', label: 'Host Path Type' },
]

const CronjobOverview = ({ cronjob, loading }) => {
  const classes = useStyles()

  const [allConfigMaps, loadingConfigMaps] = useDataLoader(configMapActions.list, {
    clusterId: cronjob?.clusterId,
    namespace: cronjob?.namespace,
  })

  const configMaps = useMemo(() => getConfigMapsForResource(cronjob, allConfigMaps), [
    allConfigMaps,
    cronjob,
  ])

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, cronjob)
  }, [cronjob])

  const podTemplateMetadata = useMemo(() => {
    return getFieldsForCard(podTemplateMetadataFields, cronjob?.podTemplate)
  }, [cronjob])

  const renderContainerName = useCallback(
    (name) => (
      <SimpleLink
        src={routes.cronjobs.container.path({
          clusterId: cronjob?.clusterId,
          id: cronjob?.id,
          containerName: name,
        })}
      >
        {name}
      </SimpleLink>
    ),
    [cronjob],
  )

  const containerColumns = useMemo(
    () => [
      { key: 'name', label: 'Name', render: renderContainerName },
      { key: 'image', label: 'image' },
    ],
    [renderContainerName],
  )

  return (
    <Progress loading={loading}>
      <article className={classes.overview}>
        <div className={classes.column}>
          <InfoCard items={metadata} title="Metadata" />
          <Card title="Config Maps" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={configMaps}
              columns={configMapColumns}
              loading={loadingConfigMaps}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <InfoCard
            items={podTemplateMetadata}
            title="Pod Template"
            footer={
              <Containers
                containers={cronjob?.containers}
                initContainerColumns={containerColumns}
                containerColumns={containerColumns}
                showInitContainers={false}
              />
            }
          />
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={cronjob?.volumes || []}
              columns={volumeTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          <Card title="Taints and Tolerations" withCustomBody>
            <Grid
              uniqueIdentifier="id"
              data={cronjob?.tolerations || []}
              columns={taintsAndTolerationsListTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
          {/*
          <Card title="Conditions" withCustomBody>
            <div>stuff goes here</div>
          </Card> */}
        </div>
      </article>
    </Progress>
  )
}

export default CronjobOverview
