import Progress from 'core/components/progress/Progress'
import React, { useCallback, useMemo } from 'react'
import InfoCard from '../common/entity/info-card'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import LabelsAndAnnotationsTabs from '../common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Card from 'core/elements/card'
import { renderAge } from '../common/entity/helpers'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Containers from '../common/entity/containers'
import { renderResourceLabels } from '../common/entity/labels-and-annotations/helpers'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { getVolumeTypeAndDescription } from '../storage/helpers'
import Grid from 'core/elements/grid'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
}))

const renderNumReplicasReady = (replicas) => {
  const { ready = 0, available = 0 } = replicas || {}
  return `${ready}/${available}`
}

const renderReplicaSetOwner = (ownerReferences = []) => {
  return (
    <div>
      {ownerReferences.map(({ kind, name }) => (
        <Text key={name} variant="caption1" component="p">
          {`${kind}/${name}`}
        </Text>
      ))}
    </div>
  )
}

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'replicas', title: 'Ready', render: renderNumReplicasReady, required: true },
  {
    id: 'selector',
    title: 'Selector',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  { id: 'ownerReferences', title: 'Controlled By', render: renderReplicaSetOwner, required: true },
  {
    id: 'age',
    title: 'Age',
    required: true,
    render: renderAge,
  },
]

const podTemplateMetadataFields = [
  {
    id: 'metadata.labels',
    title: 'Labels',
    required: true,
    render: renderResourceLabels({ separator: '=' }),
  },
  {
    id: 'metadata.creationTimestamp',
    title: 'System Prometheus',
    required: true,
    render: renderAge,
  },
]

const volumeTableColumns = [
  { key: 'name', label: 'Name' },
  {
    key: 'type',
    label: 'Type',
    formatFn: getVolumeTypeAndDescription,
  },
  { key: 'emptyDir.sizeLimit', label: 'Size Limit' },
  { key: 'emptyDir.medium', label: 'Medium' },
]

const ReplicaSetOverview = ({ replicaSet, loading }) => {
  const classes = useStyles()

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, replicaSet)
  }, [replicaSet])

  const podTemplateMetadata = useMemo(() => {
    return getFieldsForCard(podTemplateMetadataFields, replicaSet?.podTemplate)
  }, [replicaSet])

  const renderContainerName = useCallback(
    (name) => (
      <SimpleLink
        src={routes.replicaSets.container.path({
          clusterId: replicaSet?.clusterId,
          id: replicaSet?.id,
          containerName: name,
        })}
      >
        {name}
      </SimpleLink>
    ),
    [replicaSet],
  )

  const containerColumns = useMemo(
    () => [
      { key: 'name', label: 'Name', render: renderContainerName },
      { key: 'image', label: 'image' },
    ],
    [renderContainerName],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={
              <LabelsAndAnnotationsTabs
                labels={replicaSet?.labels}
                annotations={replicaSet?.annotations}
              />
            }
          />
        </div>
        <div className={classes.column}>
          <InfoCard
            items={podTemplateMetadata}
            title="Pod Template"
            footer={
              <Containers
                initContainers={replicaSet?.initContainers}
                containers={replicaSet?.containers}
                initContainerColumns={containerColumns}
                containerColumns={containerColumns}
              />
            }
          />
          <Card title="Volumes" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={replicaSet?.volumes}
              columns={volumeTableColumns}
              loading={loading}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

export default ReplicaSetOverview
