import { clientActions } from 'core/client/clientReducers'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listJobs } from '../actions'
import { jobSelector } from '../selectors'
import EntityContainerDetailsPage from 'k8s/components/common/entity/entity-container-details-page'

export default function JobContainerDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, clusterId, containerName } = match.params
  const { loading } = useListAction(listJobs, {
    params: { clusterId },
  })
  const jobs = useSelectorWithParams(jobSelector, { clusterId, useGlobalParams: false })
  const job = useMemo(() => jobs.find((job) => job.id === id), [id, jobs])
  const container = useMemo(() => job?.containers?.find((c) => c.name === containerName) || {}, [
    job,
  ])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: job?.clusterName || clusterId,
        id: job?.name || id,
        containerName,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [job?.clusterName, job?.name, id, clusterId, containerName])

  return <EntityContainerDetailsPage entity={job} container={container} loading={loading} />
}
