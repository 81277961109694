import React from 'react'
import Theme from 'core/themes/model'
import Button from 'core/elements/button'
import { makeStyles } from '@material-ui/styles'
import DisplayKeyValues from 'core/components/DisplayKeyValues'
import { SeverityTableCell } from 'k8s/components/alarms/AlarmsListPage'
import { Alarm } from 'k8s/components/alarms/model'
import DisplayLabels from 'core/components/DisplayLabels'
import Modal from 'core/elements/modal'

interface Props {
  rule: Alarm
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  upperBody: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.components.tab.border}`,
  },
}))

const RuleDetailsDialog = ({ rule, onClose }: Props) => {
  const classes = useStyles({})

  const upperValues = [
    {
      key: 'Alarm Severity',
      value: rule.severity,
      render: (value) => <SeverityTableCell value={value} />,
    },
    { key: 'Alarm Summary', value: rule.summary },
  ]
  const lowerValues = [
    { key: 'Alarm Description', value: rule.description },
    { key: 'Conditions', value: rule.query },
    { key: 'Duration', value: rule.for || 'N/A' },
    { key: 'Labels', value: rule.labels, render: (value) => <DisplayLabels labels={value} /> },
    {
      key: 'Annotations',
      value: rule.annotations,
      render: (value) => <DisplayLabels labels={value} />,
    },
  ]

  return (
    <Modal
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      onClose={onClose}
      title={rule.name}
    >
      <div className={classes.upperBody}>
        <DisplayKeyValues keyValuePairs={upperValues} />
      </div>
      <div>
        <DisplayKeyValues keyValuePairs={lowerValues} rowSpacing={24} />
      </div>
    </Modal>
  )
}
export default RuleDetailsDialog
