import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const items = [
  { label: 'warning', value: 'warning' },
  { label: 'critical', value: 'critical' },
  { label: 'fatal', value: 'fatal' },
]

interface Props {}

export default function SeverityPicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="severity" label="Severity" compact showAll items={items} {...props} />
}
