import React, { useMemo } from 'react'
import useDataLoader from 'core/hooks/useDataLoader'
import { projectAs } from 'utils/fp'
import csiDriverActions from './actions'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {
  clusterId: string
}

export default function CSIDriversPicklist({
  clusterId,
  name = 'csiDriversList',
  label = 'CSI Drivers',
  formField = false,
  ...props
}: PropsWithAsyncDropdown<Props>) {
  const [csiDrivers, loading] = useDataLoader(csiDriverActions.list, { clusterId })
  const items = useMemo(() => projectAs({ value: 'name', label: 'name' }, csiDrivers), [csiDrivers])
  return (
    <AsyncDropdown
      {...props}
      loading={loading}
      name={name}
      label={label}
      formField={formField}
      items={items}
    />
  )
}
