import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import CreateAction from 'core/actions/CreateAction'
import jsYaml from 'js-yaml'
import { trackEvent } from 'utils/tracking'
import UpdateAction from 'core/actions/UpdateAction'
import { pathStr } from 'utils/fp'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

export const podActions = ActionsSet.make<DataKeys.Pods>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'namespace'],
  entityName: entityNamesByKey.Pods,
  cacheKey: DataKeys.Pods,
})

export const listPods = podActions.add(
  new ListAction<DataKeys.Pods, { clusterId: string; namespace: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get pods', params)
    const { clusterId, namespace } = params
    return qbert.getClusterPods(clusterId, namespace)
  }).addDependency(DataKeys.PodMetrics),
)

export const createPod = podActions.add(
  new CreateAction<DataKeys.Pods, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new pod', { clusterId, namespace, yaml })
      const body = jsYaml.load(yaml)
      const created = await qbert.createPod(clusterId, namespace, body)
      trackEvent('Create New Pod', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updatePod = podActions.add(
  new UpdateAction<
    DataKeys.Pods,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
    }
  >(async ({ clusterId, namespace, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update cluster pod', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updatedPod = await qbert.updatePod(clusterId, namespace, name, body)
    trackEvent('Update Pod', { clusterId, namespace, name })
    return updatedPod
  }),
)

export const deletePod = podActions.add(
  new DeleteAction<
    DataKeys.Pods,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete pod', { id, clusterId, namespace, name })
    await qbert.deletePod(clusterId, namespace, name)
    trackEvent('Delete Pod', { clusterId, namespace, name, id })
  }),
)
