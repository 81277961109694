import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import DataKeys from 'k8s/DataKeys'
import { flatten } from 'ramda'
import { someAsync } from 'utils/async'
import { makePersistentVolumeSelector } from './selectors'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

const { qbert } = ApiClient.getInstance()

export const persistentVolumeActions = createCRUDActions(DataKeys.PersistentVolumes, {
  listFn: async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Persistent Volumes', params)
    const clusterIds = parseClusterIdsFromParams(params)
    return someAsync(clusterIds.map(qbert.getPersistentVolumes)).then(flatten)
  },
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  selectorCreator: makePersistentVolumeSelector,
})
