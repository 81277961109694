import React, { useEffect, useState } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import generateTestId from 'utils/test-helpers'
import Radio from 'core/elements/input/Radio'
import SubmitButton from 'core/components/buttons/SubmitButton'
import {
  ClusterCreateTypeNames,
  ClusterCreateTypes,
} from '../../../infrastructure/components/clusters/model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import RadioSelectableCard from './RadioSelectableCard'

interface Props {
  provider: CloudProviders
  selected: ClusterCreateTypes
  setClusterType: (value: ClusterCreateTypes) => void
  className?: string
  onComplete: (value: ClusterCreateTypes) => void
}

export default function SelectClusterTypeSection({
  provider,
  selected,
  setClusterType,
  onComplete,
  className = undefined,
}: Props) {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState('pf9')

  useEffect(() => {
    const defaultClusterCreateType =
      activeTab === 'eks' ? ClusterCreateTypes.EksCapi : ClusterCreateTypes.Capi
    setClusterType(defaultClusterCreateType)
  }, [activeTab])

  const awsClusterOptions = (
    <>
      <Tabs activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)}>
        <Tab value="pf9" label="PF9 Managed K8s Cluster">
          <div className={classes.tabContainer}>
            <div className={classes.awsDefaultTypeText}>
              <Text variant="caption2">CLUSTER WITH DEFAULT SETTINGS</Text>
            </div>
            <div className={classes.clusterTypes}>
              <RadioSelectableCard
                onClick={() => setClusterType(ClusterCreateTypes.Capi)}
                data-testid={generateTestId(ClusterCreateTypes.Capi)}
                label={ClusterCreateTypeNames.capi}
                active={selected === ClusterCreateTypes.Capi}
                subtitle="using Kubernetes Cluster API"
              />
              <RadioSelectableCard
                onClick={() => setClusterType(ClusterCreateTypes.Custom)}
                data-testid={generateTestId(ClusterCreateTypes.Custom)}
                label={`${ClusterCreateTypeNames['standard-cluster']} (to be deprecated soon)`}
                active={selected === ClusterCreateTypes.Custom}
                subtitle="using Qbert"
              />
            </div>
          </div>
        </Tab>
        <Tab value="eks" label="EKS Cluster">
          <div className={classes.tabContainer}>
            <div className={classes.awsDefaultTypeText}>
              <Text variant="caption2">CLUSTER WITH DEFAULT SETTINGS</Text>
            </div>
            <div className={classes.clusterTypes}>
              <RadioSelectableCard
                onClick={() => setClusterType(ClusterCreateTypes.EksCapi)}
                data-testid={generateTestId(ClusterCreateTypes.EksCapi)}
                label={ClusterCreateTypeNames['eks-capi']}
                active={selected === ClusterCreateTypes.EksCapi}
                subtitle="using Kubernetes Cluster API"
              />
            </div>
          </div>
        </Tab>
      </Tabs>
      <div className={classes.spacer} />
      <SubmitButton onClick={onComplete(selected)}>Start Configuration</SubmitButton>{' '}
    </>
  )

  const customizedClusterOptions =
    // provider === CloudProviders.Aws ||
    provider === CloudProviders.Azure ? (
      <>
        <Radio
          className={classes.radio}
          textWeight="light"
          data-testid={generateTestId(ClusterCreateTypes.Custom)}
          label={ClusterCreateTypeNames['custom']}
          checked={selected === ClusterCreateTypes.Custom}
          onChange={() => setClusterType(ClusterCreateTypes.Custom)}
        />
      </>
    ) : (
      <>
        <Radio
          className={classes.radio}
          textWeight="light"
          data-testid={generateTestId(ClusterCreateTypes.SingleMaster)}
          label={ClusterCreateTypeNames['single-master']}
          // info={info}
          checked={selected === ClusterCreateTypes.SingleMaster}
          onChange={() => setClusterType(ClusterCreateTypes.SingleMaster)}
        />
        <Radio
          className={classes.radio}
          textWeight="light"
          data-testid={generateTestId(ClusterCreateTypes.MultiMaster)}
          label={ClusterCreateTypeNames['multi-master']}
          // info={info}
          checked={selected === ClusterCreateTypes.MultiMaster}
          onChange={() => setClusterType(ClusterCreateTypes.MultiMaster)}
        />
      </>
    )

  return (
    <FormFieldSection
      className={clsx(classes.container, className)}
      title="Select Cluster Type"
      step={2}
    >
      <Text variant="body1">To start configurating choose the type of cluster</Text>
      <div className={classes.spacer} />

      {provider === CloudProviders.Aws ? (
        awsClusterOptions
      ) : (
        <>
          <div className={classes.defaultTypeText}>
            <Text variant="caption2">{'Cluster with default settings'.toLocaleUpperCase()}</Text>
            <Tooltip
              align={topMiddle.align}
              className={classes.info}
              message="For simple & quick cluster creation with default settings"
            >
              <Text variant="inputLabel" className={classes.hint}>
                <FontAwesomeIcon>question-circle</FontAwesomeIcon>
              </Text>
            </Tooltip>
          </div>
          <div className={classes.clusterTypes}>
            <Radio
              className={classes.radio}
              textWeight="light"
              data-testid={generateTestId(ClusterCreateTypes.OneClick)}
              label={ClusterCreateTypeNames['one-click']}
              checked={selected === ClusterCreateTypes.OneClick}
              onChange={() => setClusterType(ClusterCreateTypes.OneClick)}
            />
            <div className={classes.spacer} />
            <Text variant="caption2">{'More Customized Cluster'.toLocaleUpperCase()}</Text>
            {customizedClusterOptions}
          </div>
          <div className={classes.spacer} />
          <SubmitButton onClick={onComplete(selected)}>Start Configuration</SubmitButton>
        </>
      )}
    </FormFieldSection>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    margin: '48px 48px 48px 0',
  },
  defaultTypeText: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
  awsDefaultTypeText: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  clusterTypes: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  radio: {
    width: 'auto',
    border: `1px solid ${theme.components.selectableCard.border}`,
    padding: theme.spacing(2),
    '&:hover': {
      background: 'transparent',
      border: `1px solid ${theme.components.selectableCard.activeBorder}`,
    },
  },
  spacer: {
    height: theme.spacing(1),
  },
  tabContainer: {
    marginTop: theme.spacing(2),
  },
}))
