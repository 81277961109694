import { RootState } from 'app/store'
import { Selector, createSelector } from '@reduxjs/toolkit'
import { complement, isNil, propSatisfies, pipe, propEq } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { findClusterName } from 'k8s/util/helpers'
import createSorter from 'core/helpers/createSorter'
import { arrayIfEmpty, filterIf, emptyArr } from 'utils/fp'
import { allKey } from 'app/constants'
import { durationBetweenDates } from 'utils/misc'
import { podsByClusterIdAndNamespaceSelector } from '../pods/selectors'
import { getVolumeType, volumeTypeDescriptions } from '../storage/helpers'
import { IJobSelector } from './job-model'
import { ICronjobSelector } from './cronjob-model'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'

export const jobSelector: Selector<RootState, IJobSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Jobs>(DataKeys.Jobs, ['clusterId'], ['clusterId', 'namespace']),
  allClustersSelector,
  podsByClusterIdAndNamespaceSelector,
  (rawJobs, allClusters, podsByClusterIdAndNamespace) => {
    return rawJobs
      .map((job) => {
        const clusterId = job?.clusterId
        const clusterName = findClusterName(allClusters, clusterId)
        const selector = job?.spec?.selector?.matchLabels
        const creationTimestamp = job?.metadata?.creationTimestamp
        const associatedPods =
          podsByClusterIdAndNamespace?.[clusterId]?.[job?.namespace] || emptyArr
        const pods = associatedPods.filter((pod) =>
          pod?.metadata?.ownerReferences?.some((owner) => owner.uid === job.id),
        )
        const volumes = (job?.spec?.template?.spec?.volumes || []).map((volume) => {
          const type = getVolumeType(volume)
          return {
            ...volume,
            type,
            description: volumeTypeDescriptions[type],
          }
        })

        return {
          ...job,
          clusterName,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          creationTimestamp,
          labels: job?.metadata?.labels,
          type: 'job',
          podTemplate: job?.spec?.template,
          volumes,
          selector,
          pods,
          containers: job?.spec?.template?.spec?.containers,
          tolerations: job?.spec?.template?.spec?.tolerations,
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makeJobsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as any,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(jobSelector, selectParams, (jobs, params) => {
    const { namespace, orderBy, orderDirection } = params
    return pipe<IJobSelector[], IJobSelector[], IJobSelector[], IJobSelector[]>(
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(jobs)
  })
}

export const cronjobSelector: Selector<RootState, ICronjobSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Cronjobs>(DataKeys.Cronjobs, ['clusterId'], ['clusterId', 'namespace']),
  allClustersSelector,
  jobSelector,
  (rawCronjobs, allClusters, jobs) => {
    return rawCronjobs
      .map((cronjob) => {
        const clusterId = cronjob?.clusterId
        const clusterName = findClusterName(allClusters, clusterId)
        const creationTimestamp = cronjob?.metadata?.creationTimestamp
        const activeJobs = jobs.filter((job) => {
          const ownerReferences = job?.metadata?.ownerReferences
          return ownerReferences ? ownerReferences.some((owner) => owner.uid === cronjob.id) : false
        })
        const volumes = (cronjob?.spec?.jobTemplate?.spec?.template?.spec?.volumes || []).map(
          (volume) => {
            const type = getVolumeType(volume)
            return {
              ...volume,
              type,
              description: volumeTypeDescriptions[type],
            }
          },
        )

        return {
          ...cronjob,
          clusterName,
          age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
          creationTimestamp,
          labels: cronjob?.metadata?.labels,
          type: 'cronjob',
          jobs: activeJobs,
          pods: activeJobs.reduce((accum, job) => [...accum, ...job.pods], []),
          podTemplate: cronjob?.spec?.jobTemplate?.spec?.template,
          volumes,
          containers: cronjob?.spec?.jobTemplate?.spec?.template?.spec?.containers,
          tolerations: cronjob?.spec?.template?.spec?.tolerations,
        }
      })
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makeCronjobsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as any,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(cronjobSelector, selectParams, (cronjobs, params) => {
    const { namespace, orderBy, orderDirection } = params
    return pipe<ICronjobSelector[], ICronjobSelector[], ICronjobSelector[], ICronjobSelector[]>(
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(cronjobs)
  })
}

export const makeCronjobsAndJobsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
    jobType: allKey,
  } as any,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(cronjobSelector, jobSelector, selectParams, (cronjobs, jobs, params) => {
    const { namespace, orderBy, orderDirection, jobType } = params
    const allJobs =
      jobType === 'cronjob' ? cronjobs : jobType === 'job' ? jobs : [...cronjobs, ...jobs]
    return pipe<any[], any[], any[], any[]>(
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(allJobs)
  })
}
