import React, { useMemo, useCallback, useState, useEffect } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import RolesList from 'k8s/components/rbac/profiles/edit/RolesList'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormWrapper from 'core/components/FormWrapper'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import { propEq, lensPath, over, whereEq, view, findIndex, update, prepend, max, pipe } from 'ramda'
import useStyles from 'k8s/components/rbac/profiles/edit/useStyles'
import ProfileSummaryBox from 'k8s/components/rbac/profiles/edit/ProfileSummaryBox'
import { except, emptyArr, ensureArrayHandleNull } from 'utils/fp'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import SubmitButton from 'core/components/buttons/SubmitButton'
import {
  IRbacProfileDetails,
  IRbacRole,
  IRbacNamespaceScopedRoles,
  IRbacClusterRole,
  IRbacClusterRoleBinding,
  IRbacRoleBinding,
} from 'k8s/components/rbac/model'
import ClusterRolesList from 'k8s/components/rbac/profiles/edit/ClusterRolesList'
import RoleBindingsList from 'k8s/components/rbac/profiles/edit/RoleBindingsList'
import ClusterRoleBindingsList from 'k8s/components/rbac/profiles/edit/ClusterRoleBindingsList'
import { listRbacProfiles, updateRbacProfileDetails, loadProfileDetails } from '../new-actions'
import useListAction from 'core/hooks/useListAction'
import { rbacProfilesSelector } from '../selectors'
import { useSelector } from 'react-redux'
import useUpdateAction from 'core/hooks/useUpdateAction'

const backUrl = routes.rbacProfiles.list.path()
const defaultNamespaceScoped: IRbacNamespaceScopedRoles[] = []
const defaultClusterRole: IRbacClusterRole[] = []
const defaultClusterRoleBinding: IRbacClusterRoleBinding[] = []

const namespaceScopedLens = lensPath<IRbacProfileDetails, IRbacNamespaceScopedRoles[]>([
  'data',
  'namespaceScoped',
])
const getRolesLens = (namespaceIdx) =>
  lensPath<IRbacProfileDetails, IRbacRole[]>(['data', 'namespaceScoped', namespaceIdx, 'roles'])

const getRoleBindingsLens = (namespaceIdx) =>
  lensPath<IRbacProfileDetails, IRbacRoleBinding[]>([
    'data',
    'namespaceScoped',
    namespaceIdx,
    'roleBindings',
  ])

const clusterRoleLens = lensPath<IRbacProfileDetails, IRbacClusterRole[]>([
  'data',
  'clusterScoped',
  'clusterRole',
])

const clusterRoleBindingLens = lensPath<IRbacProfileDetails, IRbacClusterRoleBinding[]>([
  'data',
  'clusterScoped',
  'clusterRoleBinding',
])

const EditRbacProfile = () => {
  const classes = useStyles()
  const { match, history } = useReactRouter()
  const { id } = match.params

  const { loading: loadingProfiles } = useListAction(listRbacProfiles)
  const profiles = useSelector(rbacProfilesSelector)

  const [profileDetails, setProfileDetails] = useState<IRbacProfileDetails>()

  const { update: updateRbacProfile, updating: updatingRbacProfile } = useUpdateAction(
    updateRbacProfileDetails,
  )

  const [errorMessage, setErrorMessage] = useState<string>('')

  const profile = useMemo(() => profiles.find(propEq('id', id)), [profiles, id])
  const readOnly = profile?.status?.phase === 'published'

  const profileSummary = useMemo(() => {
    const data = profileDetails?.data
    return {
      rolesCount: data?.namespaceScoped?.reduce((acc, { roles }) => {
        return acc + (roles?.length || 0)
      }, 0),
      clusterRolesCount: data?.clusterScoped?.clusterRole?.length || 0,
      roleBindingsCount: data?.namespaceScoped?.reduce((acc, { roleBindings }) => {
        return acc + (roleBindings?.length || 0)
      }, 0),
      clusterRoleBindingsCount: data?.clusterScoped?.clusterRoleBinding?.length || 0,
    }
  }, [profileDetails])

  useEffect(() => {
    if (profile) {
      const loadDetails = async () => {
        const {
          metadata: { name, namespace },
        } = profile
        const details = await loadProfileDetails(namespace, name)
        setProfileDetails(details)
      }
      loadDetails()
    }
  }, [profile])

  const handleSubmit = useCallback(async () => {
    const {
      metadata: { name, namespace },
    } = profile
    const { success } = await updateRbacProfile({
      name,
      namespace,
      details: profileDetails,
    })
    if (success) {
      history.push(backUrl)
    } else {
      setErrorMessage('ERROR: Error saving profile')
    }
  }, [profile, profileDetails])

  // If the namespace block is not present we add a new one under the "namespaceScoped" key
  const getDetailsWithNamespace = useCallback(
    (namespace): [IRbacProfileDetails, number] => {
      const namespaceIdx = findIndex(
        propEq('name', namespace),
        profileDetails?.data?.namespaceScoped || emptyArr,
      )
      const defaultNamespace: IRbacNamespaceScopedRoles = {
        name: namespace,
        roles: [],
        roleBindings: [],
      }
      const detailsWithNamespace =
        namespaceIdx < 0
          ? over(namespaceScopedLens, prepend(defaultNamespace), profileDetails)
          : profileDetails

      return [detailsWithNamespace, max<number>(namespaceIdx, 0)]
    },
    [profileDetails],
  )

  /*********************************
   *     CLUSTERS HANDLERS
   *********************************/

  const handleAddRole = useCallback(
    (item: IRbacRole) => {
      const {
        metadata: { namespace },
      } = item
      const [detailsWithNamespace, namespaceIdx] = getDetailsWithNamespace(namespace)
      const rolesLens = getRolesLens(namespaceIdx)
      const updatedDetails = over(
        rolesLens,
        pipe(ensureArrayHandleNull, prepend(item)),
      )(detailsWithNamespace)
      setProfileDetails(updatedDetails)
    },
    [getDetailsWithNamespace],
  )

  const handleUpdateRole = useCallback(
    (item: IRbacRole) => {
      const {
        metadata: { namespace, name },
      } = item
      const [detailsWithNamespace, namespaceIdx] = getDetailsWithNamespace(namespace)
      const rolesLens = getRolesLens(namespaceIdx)
      const roles = view(rolesLens, detailsWithNamespace)
      const idx = findIndex(whereEq({ name, namespace }), roles)
      const updatedDetails = over(rolesLens, update(idx, item))(detailsWithNamespace)
      setProfileDetails(updatedDetails)
    },
    [getDetailsWithNamespace],
  )

  const handleRemoveRole = useCallback(
    (item: IRbacRole) => {
      const {
        metadata: { namespace },
      } = item
      const [detailsWithNamespace, namespaceIdx] = getDetailsWithNamespace(namespace)
      const rolesLens = getRolesLens(namespaceIdx)
      const updatedDetails = over(rolesLens, except(item))(detailsWithNamespace)
      setProfileDetails(updatedDetails)
    },
    [getDetailsWithNamespace],
  )

  /*********************************
   *   CLUSTER ROLES HANDLERS
   *********************************/

  const handleAddClusterRole = useCallback(
    (item: IRbacClusterRole) => {
      const updatedDetails = over(
        clusterRoleLens,
        pipe(ensureArrayHandleNull, prepend(item)),
      )(profileDetails)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  const handleUpdateClusterRole = useCallback(
    (item: IRbacClusterRole) => {
      const {
        metadata: { name, creationTimestamp },
      } = item
      const clusterRoles = view(clusterRoleLens, profileDetails)
      const idx = findIndex(whereEq({ metadata: { name, creationTimestamp } }), clusterRoles)
      const updatedDetails = over(clusterRoleLens, update(idx, item))(profileDetails)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  const handleRemoveClusterRole = useCallback(
    (item: IRbacClusterRole) => {
      const updatedDetails = over(clusterRoleLens, except(item))(profileDetails)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  /*********************************
   *   ROLE BINDINGS HANDLERS
   *********************************/

  const handleAddRoleBinding = useCallback(
    (item: IRbacRoleBinding) => {
      const {
        metadata: { namespace },
      } = item
      const [detailsWithNamespace, namespaceIdx] = getDetailsWithNamespace(namespace)
      const rolesLens = getRoleBindingsLens(namespaceIdx)
      const updatedDetails = over(
        rolesLens,
        pipe(ensureArrayHandleNull, prepend(item)),
      )(detailsWithNamespace)
      setProfileDetails(updatedDetails)
    },
    [getDetailsWithNamespace, profileDetails],
  )

  const handleUpdateRoleBinding = useCallback(
    (item: IRbacRoleBinding) => {
      const {
        metadata: { namespace, name },
      } = item
      const [detailsWithNamespace, namespaceIdx] = getDetailsWithNamespace(namespace)
      const rolesLens = getRoleBindingsLens(namespaceIdx)
      const roles = view(rolesLens, detailsWithNamespace)
      const idx = findIndex(whereEq({ name, namespace }), roles)
      const updatedDetails = over(rolesLens, update(idx, item))(detailsWithNamespace)
      setProfileDetails(updatedDetails)
    },
    [getDetailsWithNamespace, profileDetails],
  )

  const handleRemoveRoleBinding = useCallback(
    (item: IRbacRoleBinding) => {
      const {
        metadata: { namespace },
      } = item
      const [detailsWithNamespace, namespaceIdx] = getDetailsWithNamespace(namespace)
      const roleBindingsLens = getRoleBindingsLens(namespaceIdx)
      const updatedDetails = over(roleBindingsLens, except(item))(detailsWithNamespace)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  /*********************************
   * CLUSTER ROLE BINDINGS HANDLERS
   *********************************/

  const handleAddClusterRoleBinding = useCallback(
    (item: IRbacClusterRoleBinding) => {
      const updatedDetails = over(
        clusterRoleBindingLens,
        pipe(ensureArrayHandleNull, prepend(item)),
      )(profileDetails)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  const handleUpdateClusterRoleBinding = useCallback(
    (item: IRbacClusterRoleBinding) => {
      const {
        metadata: { name, creationTimestamp },
      } = item
      const clusterRoles = view(clusterRoleBindingLens, profileDetails)
      const idx = findIndex(whereEq({ metadata: { name, creationTimestamp } }), clusterRoles)
      const updatedDetails = over(clusterRoleBindingLens, update(idx, item))(profileDetails)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  const handleRemoveClusterRoleBinding = useCallback(
    (item: IRbacClusterRoleBinding) => {
      const updatedDetails = over(clusterRoleBindingLens, except(item))(profileDetails)
      setProfileDetails(updatedDetails)
    },
    [profileDetails],
  )

  return (
    <FormWrapper
      title={readOnly ? 'RBAC Profile Details' : 'Edit RBAC Profile'}
      backUrl={backUrl}
      message={updatingRbacProfile ? 'Submitting form...' : 'Loading Profile...'}
      loading={loadingProfiles || updatingRbacProfile || !profileDetails}
      renderContentOnMount={false}
    >
      <DocumentMeta title="Edit RBAC Profile" bodyClasses={['form-view']} />
      <div className={classes.profileContainer}>
        <ProfileSummaryBox profile={profile} {...profileSummary} />
        <div className={classes.rightSide}>
          {profileDetails && (
            <Tabs route={routes.rbacProfiles.edit}>
              <Tab value="roles" label="Roles">
                <RolesList
                  clusterId={profile?.baseClusterId}
                  clusterName={profile?.baseClusterName}
                  namespaceScopedRoles={
                    profileDetails?.data?.namespaceScoped || defaultNamespaceScoped
                  }
                  onAdd={handleAddRole}
                  onUpdate={handleUpdateRole}
                  onRemove={handleRemoveRole}
                  readOnly={readOnly}
                />
              </Tab>
              <Tab value="cluster-roles" label="Cluster Roles">
                <ClusterRolesList
                  clusterId={profile?.baseClusterId}
                  clusterRoles={
                    profileDetails?.data?.clusterScoped?.clusterRole || defaultClusterRole
                  }
                  onAdd={handleAddClusterRole}
                  onUpdate={handleUpdateClusterRole}
                  onRemove={handleRemoveClusterRole}
                  readOnly={readOnly}
                />
              </Tab>
              <Tab value="role-bindings" label="Role Bindings">
                <RoleBindingsList
                  profileName={profile?.name}
                  clusterId={profile?.baseClusterId}
                  namespaceScopedRoles={
                    profileDetails?.data?.namespaceScoped || defaultNamespaceScoped
                  }
                  onAdd={handleAddRoleBinding}
                  onUpdate={handleUpdateRoleBinding}
                  onRemove={handleRemoveRoleBinding}
                  readOnly={readOnly}
                />
              </Tab>
              <Tab value="cluster-role-bindings" label="Cluster Role Bindings">
                <ClusterRoleBindingsList
                  profileName={profile?.name}
                  clusterId={profile?.baseClusterId}
                  clusterRoleBindings={
                    profileDetails?.data?.clusterScoped?.clusterRoleBinding ||
                    defaultClusterRoleBinding
                  }
                  onAdd={handleAddClusterRoleBinding}
                  onUpdate={handleUpdateClusterRoleBinding}
                  onRemove={handleRemoveClusterRoleBinding}
                  readOnly={readOnly}
                />
              </Tab>
            </Tabs>
          )}
          {!readOnly && (
            <SubmitButton className={classes.submitButton} onClick={handleSubmit}>
              Save
            </SubmitButton>
          )}
        </div>
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </FormWrapper>
  )
}

export default EditRbacProfile
