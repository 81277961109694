import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import React from 'react'
import { columns } from 'k8s/components/pods/PodsListPage'
import { deletePod } from 'k8s/components/pods/new-actions'
import ListContainer from 'core/containers/ListContainer'
import { pick } from 'ramda'
import { podsSelector } from 'k8s/components/pods/selectors'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'

type ModelDataKey = DataKeys.Pods
type SelectorModel = ArrayElement<ReturnType<typeof podsSelector>>

const useStyles = makeStyles<Theme>((theme) => ({
  pods: {
    marginTop: '16px',
  },
}))

const usePrefParams = createUsePrefParamsHook('Pods', listTablePrefs)

export default function EntityPodsPage({ pods = [], loading, reload }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = usePrefParams({})

  return (
    <article className={classes.pods}>
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        dataKey={DataKeys.Pods}
        searchTargets={['name']}
        uniqueIdentifier="id"
        loading={loading}
        onRefresh={reload}
        data={pods}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deletePod}
        {...pick(listTablePrefs, params)}
      />
    </article>
  )
}
