import React, { forwardRef, useEffect } from 'react'
import PicklistDefault from 'core/elements/dropdown/AsyncDropdown'
import { head } from 'ramda'

const Picklist: any = PicklistDefault // types on forward ref .js file dont work well.

// Backend is dumb and version on a created EKS cluster does not match the version here
export const getVersionFromClusterVersion = (clusterVersion) => {
  if (!clusterVersion) {
    return ''
  }
  // Remove v from beginning of clusterVersion
  const parsedClusterVersion =
    clusterVersion?.[0] === 'v' ? clusterVersion.slice(1) : clusterVersion
  const versions = ['1.23.7', '1.22.10', '1.21.13']
  const matchingVersion = versions.find((version) => {
    return parsedClusterVersion.slice(0, 4) === version.slice(0, 4)
  })
  return matchingVersion || ''
}

const versions = [
  { value: '1.23.7', label: '1.23.7' },
  { value: '1.22.10', label: '1.22.10' },
  { value: '1.21.13', label: '1.21.13' },
]

interface Props {
  onChange: (key: string) => void
  value: string
  selectFirst?: boolean
}

const EksKubernetesVersionPicklist = forwardRef(
  ({ onChange, value, selectFirst, ...rest }: Props, ref) => {
    useEffect(() => {
      if (!value && selectFirst) {
        onChange(head(versions)?.value)
      }
    }, [selectFirst, value])

    return <Picklist {...rest} value={value} onChange={onChange} ref={ref} items={versions} />
  },
)

export default EksKubernetesVersionPicklist
