import React, { useEffect, useMemo } from 'react'
import { head, isEmpty, prop, propOr } from 'ramda'
import { emptyObj, projectAs } from 'utils/fp'
import { allKey, UserPreferences } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { sessionStoreKey } from 'core/session/sessionReducers'
import { isDecco } from 'core/utils/helpers'
import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const defaultParamsAllClusterSelector = makeParamsAllClustersSelector()

export interface Props {
  onlyMasterNodeClusters?: boolean
  onlyPrometheusEnabled?: boolean
  onlyKubevirtClusters?: boolean
  onlyHealthyClusters?: boolean
  onlyEcoEnabledClusters?: boolean
  filterFn?: (clusters: CombinedClusterSelector[]) => CombinedClusterSelector[]
  setInitialCluster?: boolean
}

export default function ClusterPicklist({
  name = 'clusterId',
  label = 'Cluster:',
  selectFirst = true,
  onlyMasterNodeClusters = false,
  onlyPrometheusEnabled = false,
  onlyHealthyClusters = true,
  onlyEcoEnabledClusters = true,
  onlyKubevirtClusters = false,
  setInitialCluster = true,
  loading = false,
  compact = true,
  showAll = true,
  onChange,
  value,
  filterFn,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const defaultParams = useMemo(
    () => ({
      masterNodeClusters: onlyMasterNodeClusters,
      prometheusClusters: onlyPrometheusEnabled,
      healthyClusters: onlyHealthyClusters,
      ecoEnabledClusters: onlyEcoEnabledClusters,
      kubevirtClusters: onlyKubevirtClusters,
    }),
    [onlyMasterNodeClusters, onlyPrometheusEnabled, onlyHealthyClusters, onlyKubevirtClusters],
  )
  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)

  const allClusters: CombinedClusterSelector[] = useSelectorWithParams(
    defaultParamsAllClusterSelector,
    defaultParams,
  )

  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const { uuid: defaultClusterId } = (prefs[UserPreferences.Cluster] || emptyObj) as {
    uuid: string
  }

  const allClustersLoading = clustersLoading || importedClustersLoading
  const session = useAppSelector(prop(sessionStoreKey))
  const { features } = session
  const isDeccoEnv = isDecco(features)

  useEffect(() => {
    if (setInitialCluster && isDeccoEnv) {
      fetchUserDefaults(UserPreferences.Cluster)
    }
  }, [setInitialCluster, isDeccoEnv])

  const filteredClusters = useMemo(() => (filterFn ? filterFn(allClusters) : allClusters), [
    allClusters,
  ])
  const options = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'uuid' }, filteredClusters)]
  }, [filteredClusters])

  useEffect(() => {
    if (value) return
    if (isEmpty(options) || !setInitialCluster) return

    if (isDeccoEnv && defaultClusterId) {
      const option = options.find((o) => o.value === defaultClusterId)
      option ? onChange(option.value) : onChange(propOr(allKey, 'value', head(options)))
    } else if (selectFirst) {
      // Always select the first loaded item
      onChange(propOr(allKey, 'value', head(options)))
    }
  }, [options, defaultClusterId, isDeccoEnv, allClustersLoading])

  return (
    <AsyncDropdown
      name={name}
      compact={compact}
      showAll={showAll}
      items={options}
      onChange={onChange}
      label={label}
      loading={loading || allClustersLoading}
      value={value}
      {...rest}
    />
  )
}
