import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import Progress from 'core/components/progress/Progress'
import CancelButton from 'core/components/buttons/CancelButton'
import SubmitButton from 'core/components/buttons/SubmitButton'
import { deleteDeployment } from '../deployments/new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

const protectedNamespaces = [
  'platform9-system',
  'kube-system',
  'pf9-olm',
  'pf9-operators',
  'pf9-monitoring',
]

const DeleteDeploymentDialog = ({ rows: [deployment], onClose }) => {
  const { clusterId, namespace, name, id } = deployment
  const { update, updating } = useUpdateAction(deleteDeployment)
  const allowDelete = !protectedNamespaces.includes(namespace)

  const handleSubmit = async () => {
    await update({ clusterId, namespace, name, id })
    onClose()
  }

  return (
    <Dialog open onClose={onClose}>
      <Progress loading={updating}>
        <DialogTitle>Delete Deployment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {allowDelete
              ? `Delete deployment: ${name}?`
              : 'Protected Namespace - Please contact Platform9 for assistance'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {allowDelete ? (
            <>
              <CancelButton onClick={onClose} />
              <SubmitButton onClick={handleSubmit} />
            </>
          ) : (
            <CancelButton onClick={onClose}>Close</CancelButton>
          )}
        </DialogActions>
      </Progress>
    </Dialog>
  )
}

export default DeleteDeploymentDialog
