import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import { notFoundErr } from 'app/constants'
import createCRUDActions from 'core/helpers/createCRUDActions'
import yaml from 'js-yaml'
import DataKeys from 'k8s/DataKeys'
import { flatten, propEq } from 'ramda'
import { someAsync } from 'utils/async'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import { storageClassSelector } from './selectors'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'

// DEPRECATED Please use newActions.ts instead

const { qbert } = ApiClient.getInstance()

const storageClassActions = createCRUDActions(DataKeys.StorageClasses, {
  listFn: async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Storage Classes', params)
    const clusterIds = parseClusterIdsFromParams(params)
    return someAsync(clusterIds.map(qbert.getClusterStorageClasses)).then(flatten)
  },
  deleteFn: async ({ id }, currentItems) => {
    const item = currentItems.find(propEq('id', id))
    if (!item) {
      throw new Error(notFoundErr)
    }
    const { clusterId, name } = item
    Bugsnag.leaveBreadcrumb('Attempting to delete Storage Class', { clusterId, name })
    const result = await qbert.deleteStorageClass(clusterId, name)
    trackEvent('Delete Storage Class', { clusterId, name })
    return result
  },
  createFn: async ({ clusterId, storageClassYaml }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create Storage Class', { clusterId, storageClassYaml })
    const body = yaml.load(storageClassYaml)
    const created = await qbert.createStorageClass(clusterId, body)
    trackEvent('Create Storage Class', {
      id: pathStr('metadata.uid', created),
      name: pathStr('metadata.name', created),
    })
    return created
  },
  updateFn: async ({ clusterId, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Storage Class', {
      clusterId,
      name,
      body,
    })
    await qbert.updateClusterStorageClass(clusterId, name, body)
    trackEvent('Update Storage Class', { clusterId, name })
  },
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: 'Storage Classes',
  selector: storageClassSelector,
})

export default storageClassActions
