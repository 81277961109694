import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DeleteDeploymentDialog from '../pods/delete-deployment-dialog'
import DataKeys from 'k8s/DataKeys'
import { createResourceLabelsCell } from '../common/entity/labels-and-annotations/helpers'
import { IDeploymentSelector } from './model'
import Progress from 'core/components/progress/Progress'
import { useSelector } from 'react-redux'
import { podActions } from '../pods/actions'
import { isDataLoadingSelector } from 'core/hooks/useDataLoaderWithMessage'
import DocumentMeta from 'core/components/DocumentMeta'
import { listDeployments, deleteDeployment } from './new-actions'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { deploymentsSelector } from './selectors'

import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import AddWorkloadResourcePage from 'k8s/components/common/entity/AddResourcePage'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ModelDataKey = DataKeys.Deployments
type SelectorModel = ArrayElement<ReturnType<typeof deploymentsSelector>>
type ActionParams = InferActionParams<typeof listDeployments>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  namespace?: string
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'Deployments',
  listTablePrefs,
)

function RenderPods({
  value: pods,
  item: { runningPods = 0 },
}: GridCellProps<SelectorModel, IDeploymentSelector['deploymentPods']>) {
  const loading = useSelector(isDataLoadingSelector(podActions.cacheKey))
  if (loading) {
    return <Progress inline overlay={false} message="" loading />
  }
  if (!pods) return <Text variant="body2">0</Text>
  return (
    <Text variant="body2">
      {runningPods}/{pods?.length}
    </Text>
  )
}

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id }) => routes.deployments.details.path({ clusterId, id }),
    }),
  },
  { key: 'namespace', label: 'Namespace' },
  { key: 'clusterName', label: 'Cluster', width: 'medium' },
  { key: 'age', label: 'Age' },
  { key: 'deploymentPods', label: 'Pods', CellComponent: RenderPods },
  {
    key: 'selectors',
    label: 'Selectors',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=', variant: 'default' }),
  },
  {
    key: 'labels',
    label: 'Labels',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'annotations',
    label: 'Annotations',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
  },
]

export default function DeploymentsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listDeployments, {
    params,
    requiredParams,
  })
  const data = useAppSelector(deploymentsSelector)

  return (
    <>
      <DocumentMeta title="Deployments" />
      <AddWorkloadResourcePage resourceType="deployment" addRoute={routes.deployments.add} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Deployments}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.deployments.add.path()}
        addText="Add Deployment"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteDeployment}
        DeleteDialogComponent={DeleteDeploymentDialog}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
